import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react'
import AttToken from '../../helpers/attToken';
import Anonimo from '../../service/Anonimo';
import { ChecklistType, ItemChecklist } from '../../service/Checklists';
import { Accordion, AccordionSummary, TextField, Tooltip, Typography } from '@material-ui/core';
import { MdOutlineExpandMore } from 'react-icons/md';
import SmallTable from '../Skeletons/SmallTable';
import { Autocomplete, Skeleton } from '@mui/material';
import { getMuiTheme } from "../TableHelpers/options";
import html2canvas from 'html2canvas';
import { FaFilePdf } from 'react-icons/fa';
import pdfMake from "pdfmake/build/pdfmake";
import { downloadExcel } from 'react-export-table-to-excel';
import { useTranslation } from 'react-i18next';
import { ChecklistReportType } from '../../service/Reports';
import { StyledDiv } from '../TableHelpers/TableStyle';
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RiFileExcel2Fill } from 'react-icons/ri';
import ModalDetailPrint from '../Reports/ModalDetailPrint/ModalDetailPrint';
import Pagination from '@mui/material/Pagination';
import { ChamadoType } from './Types';
import { IoFilterSharp } from "react-icons/io5";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ptBR from 'date-fns/locale/pt-BR';
import '@mui/x-date-pickers/themeAugmentation'
import './styles.css'

const anonimoService = new Anonimo();

const currentDate = new Date();
const oneWeekAgo = new Date(currentDate);

function FilledAccordeon({
  execucoes,
  isLoading,
  modelo
}: {
  execucoes: any[],
  isLoading: boolean,
  modelo: { label: string, value: string } | null
}) {
  const { t } = useTranslation('translation');

  const mapKeys = {
    inicio: t('Reports.columns.start'),
    fim: t('Reports.columns.end'),
    duracao: `${t('preenchimento.duracao')}`,
    preenchimento: `${t('preenchimento.preenchimentoQtd')}`,
    camposOcultos: 'Chave Campo Oculto',
    camposOcultosValor: 'Valor Campo Oculto'
  }

  const [currentExecution, setCurrentExecution] = useState<ChecklistReportType | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const printToPdf = () => {
    const table = document.getElementById("print_to_pdf");
    if (table) {
      html2canvas(table)?.then(canvas => {
        const data = canvas?.toDataURL();
        const pdfExportSetting = {
          PageOrientation: 'landscape',
          content: [
            {
              pageSize: 'a4',
              compress: true,
              image: data,
              width: 400,
            }
          ]
        };
        pdfMake?.createPdf(pdfExportSetting)?.download("Relatório Axyma Forms.pdf");
      });
    }
  };

  const columns = [
    {
      name: "inicio",
      label: `${t('preenchimento.visualizar')}`,
      options: {
        display: false,
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "inicio",
      label: `${t('preenchimento.iniciadoEm')}`,
      options: {
        display: true,
        customBodyRender: (value: number) => {
          return (
            <span>
              {new Date(value).toLocaleString('pt-br')}
            </span>)
        },
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "fim",
      label: `${t('preenchimento.FinalizadoEm')}`,
      options: {
        display: true,
        customBodyRender: (value: number) => {
          return <span>
            {new Date(value).toLocaleString('pt-br')}
          </span>
        },
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "duracao",
      label: `${t('preenchimento.duracao')}`,
      options: {
        display: true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "preenchimento",
      label: `${t('preenchimento.preenchimentoQtd')}`,
      options: {
        display: true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "camposOcultos",
      display: true,
      label: `Chave Campo Oculto`,
      options: {
        customBodyRender: (value: { [key: string]: string }) => {
          return (
            <>
              {
                value ? Object.keys(value).map((val, i) => (
                  <span key={i}>{`${val} - `}</span>
                )) : <></>}
            </>
          )
        },
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "subItemIdentificador",
      label: `Subitem Identificador`,
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "identificador",
      label: `Identificador`,
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "camposOcultos",
      display: true,
      label: `Valor Campo Oculto`,
      options: {
        customBodyRender: (value: { [key: string]: string }) => {
          return (
            <>
              {
                value ? Object.values(value).map((val, i) => (
                  <span key={i}>{`${val} - `}</span>
                )) : <></>}
            </>
          )
        },
        filter: false,
        sort: true,
        sortThirdClickReset: true
      },
    },
    {
      name: "chamados",
      display: true,
      label: `Chamados Emitidos na Raiz`,
      options: {
        customBodyRender: (value: ChamadoType[]) => {
          return (
            <>
              {value && value.length ? (
                <span>{`${value.length} ${value.length > 1 ? 'Chamados Emitidos na Raiz' : 'Chamado Emitido na Raiz'}`}</span>
              ) : (<span>Nenhum Chamado Emitido na Raiz</span>)}
            </>
          )
        },
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order: 'asc' | 'desc' | 'none') => {
          return (obj1: { data: ChamadoType[] }, obj2: { data: ChamadoType[] }) => {
            const obj1Length = obj1.data ? obj1.data.length : 0;
            const obj2Length = obj2.data ? obj2.data.length : 0;
            if (order === 'asc') {
              return obj2Length - obj1Length;
            } else {
              return obj1Length - obj2Length;
            }
          };
        }
      }
    },
    {
      name: "itens",
      display: true,
      label: `Chamados Emitidos em Subitems`,
      options: {
        customBodyRender: (value: ItemChecklist[]) => {
          if (value && value.length) {
            let chamadosAbertos = 0;
            value.forEach(e => e.subitens.forEach(sub => {
              if (sub.chamados && sub.chamados.length) { chamadosAbertos += sub.chamados.length }
            }))
            return (
              <>
                {chamadosAbertos ? (
                  <span>{`${chamadosAbertos} ${chamadosAbertos > 1 ? 'Chamados Emitidos em Subitems' : 'Chamado Emitido em Subitems'}`}</span>
                ) : (<span>Nenhum Chamado Emitido em Subitems</span>)}
              </>
            )
          } else {
            return <></>
          }
        },
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order: 'asc' | 'desc' | 'none') => {
          return (obj1: { data: ItemChecklist[] }, obj2: { data: ItemChecklist[] }) => {
            let obj1Length = 0;
            let obj2Length = 0;
            obj1.data.forEach(e => e.subitens.forEach(sub => {
              if (sub.chamados && sub.chamados.length) { obj1Length += sub.chamados.length }
            }))
            obj2.data.forEach(e => e.subitens.forEach(sub => {
              if (sub.chamados && sub.chamados.length) { obj2Length += sub.chamados.length }
            }))
            if (order === 'asc') {
              return obj2Length - obj1Length;
            } else {
              return obj1Length - obj2Length;
            }
          };
        }
      },
    }
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      const execution = execucoes?.find(e => e.inicio === Number(rowData[0])) || null
      if (execution)
        setCurrentExecution(execution);
      setOpenModal(true);
    },
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  const tableOptions: MUIDataTableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    customToolbar: () => {
      return (
        <>
          <Tooltip title={'Download PDF'}>
            <button
              style={{ marginTop: '5px', color: 'gray', borderRadius: '40px 40px 40px 40px' }}
              className="button is-white"
              onClick={printToPdf}>
              <FaFilePdf />
            </button>
          </Tooltip>
          <Tooltip title={'Download Excel'}>
            <button
              style={{ marginTop: '5px', color: 'gray', borderRadius: '40px 40px 40px 40px' }}
              className="button is-white"
              onClick={() => {
                const current = execucoes as ChecklistReportType[];
                const bodyArray = [] as ChecklistReportType[];
                const headerArray: string[] = [];
                Object.keys(mapKeys).forEach(key => {
                  headerArray.push(mapKeys[key as keyof typeof mapKeys])
                })
                current.forEach((elem: any) => {
                  const objToInstert: any = {}
                  Object.keys(mapKeys).forEach((key: any) => {
                    if (key === 'camposOcultos') {
                      objToInstert['camposOcultos'] = String(Object.keys(elem[key] || {})).replaceAll(',', ' | ')
                      objToInstert['camposOcultosValor'] = String(Object.values(elem[key] || {})).replaceAll(',', ' | ')
                    } else if (key === 'inicio' || key === 'fim') {
                      objToInstert[key] = new Date(Number(elem[key])).toLocaleString('pt-br').replace(',', ' ')
                    } else if (key !== 'camposOcultosValor') {
                      objToInstert[key] = elem[key]
                    }
                  }
                  )
                  bodyArray.push(objToInstert)
                })
                downloadExcel({
                  fileName: modelo?.label as string,
                  sheet: "react-export-table-to-excel",
                  tablePayload: {
                    header: headerArray || [''],
                    body: bodyArray as unknown as any
                  },
                });
              }}>
              <RiFileExcel2Fill />
            </button>
          </Tooltip>
        </>
      )
    },
    downloadOptions: {
      filename: modelo?.label as string,
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const newData = data?.map((ele: any) => {
        ele.data[0] = new Date(ele.data[0]).toLocaleString('pt-br')
        ele.data[1] = new Date(ele.data[1]).toLocaleString('pt-br')
        ele.data[5] = Object?.keys(ele.data[6] || {})
        ele.data[6] = Object?.values(ele.data[6] || {})
        return ele
      })
      return "\uFEFF" + buildHead(columns) + buildBody(newData);
    },
    print: true,
    download: true,
    filter: false,
    filterType: 'textField',
    responsive: `vertical`,
    rowsPerPageOptions: [10, 15, Number(execucoes?.length)]
  };

  const getMuiTheme = () => createTheme({
    components: {
      MuiFormGroup: {
        styleOverrides: {
          root: {
              "&>:nth-child(1)":{
                display:'none'
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            width: 'fit-content',
            margin: 0,
            padding: 0
          }
        }
      },

      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: '0 10px',
            button: {
              display: 'flex',
              justifySelf: 'flex-start',
              justifyContent: 'flex-start',
              width: '100%',
            },
            span: {
              width: '100%',
              margin: 0,
              padding: 0
            }
          },
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
          }
        }
      },

      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            width: '35dvw',
          }
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            td: {
              height: 'fit-content',
              margin: 0,
              padding: '3px 10px',
              width: 'fit-content'
            },
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          item: {
            margin: 0,
            padding: 0
          },
          root: {
            margin: 0,
            padding: 0
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            padding: '10px'
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            fontSize: '12px'
          },
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#FFF",
          },
        }
      },
    },
  })

  return (
    <>
      {openModal && currentExecution && (
        <ModalDetailPrint
          numberOfTicktes={currentExecution.chamadosConfig?.limiteChamado}
          selected={currentExecution}
          openModal={openModal}
          setOpenModal={setOpenModal}
          commingFromFilled={true} />
      )}
      {isLoading
        ? <Skeleton variant='rounded' height={450} sx={{ marginTop: '20px' }} />
        : execucoes && <div style={{ marginTop: '20px', marginBottom: '5%' }}>
          <StyledDiv theme={JSON.stringify(window.screen.width)}
            id="print_to_pdf" style={{ width: '100%' }}>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={<p style={{ wordBreak: 'break-word' }}>
                  {t('preenchimento.execucoes')}: <b>
                    {modelo?.label as string}
                  </b>
                </p>
                }
                data={execucoes}
                columns={columns}
                options={{ ...tableOptions, ...translatedTextLabels }}
              />
            </ThemeProvider>
          </StyledDiv>
        </div>
      }
    </>
  )
}

export default function FilledForms() {
  const [modelo, setModelo] = useState<{ label: string, value: string } | null>(null)
  const [inicio, setInicio] = useState<Date | number | null>(oneWeekAgo.setDate(currentDate.getDate() - 7))
  const [final, setFinal] = useState<Date | null>(new Date())

  const { data: modelosAnonimo, isLoading: isLoadingModelos } = useQuery({
    queryKey: ['GET_MODELOS_ANONIMOS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await anonimoService.listaModelosChecklistAnonimo(token)
        return response;
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { mutate, data: execucoes, isLoading } = useMutation({
    mutationKey: ['GET_EXECUTION'],
    mutationFn: async (value: string) => {
      const token = await AttToken()
      if (token) {
        const response = await anonimoService.listaHistoricoAnonimo(
          token,
          value,
          new Date(inicio as Date).getTime(),
          new Date(final as Date).getTime());
        return response
      }
    }
  })

  const getMuiTheme = () => createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            textAlign: 'center',
            width: 110,
            maxWidth: 110
          }
        }
      },
    },
  })

  return (
    <>
      {isLoadingModelos
        ?
        <>
          <Skeleton variant='rounded' height={40} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px',
              alignItems: 'center'
            }}>
            <div style={{ display: 'flex' }}>
              <Skeleton variant='rounded' height={40} width={100} />&nbsp;
              <Skeleton variant='rounded' height={40} width={100} />
            </div>
            <Skeleton variant='rounded' height={40} width={80} />
          </div>
        </>
        : modelosAnonimo &&
        <>
          <Autocomplete
            noOptionsText={'Sem modelos disponíveis'}
            id="combo-box-demo"
            sx={{ marginTop: '5px' }}
            filterSelectedOptions
            disableClearable
            options={
              (modelosAnonimo as ChecklistType[])
                ?.sort((a, b) => { return a?.nome?.toLowerCase() > b?.nome?.toLowerCase() ? 1 : - 1 })
                ?.map((ele) => ({
                  label: ele.nome,
                  value: ele.id,
                }))}
            onChange={(event: any, newValue: { label: string, value: string } | null, reason: any, details: any) => {
              setModelo(details.option)
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.value}>
                <span>{option.label}</span>
              </li>)}
            renderInput={(params) => <TextField {...params} label={'Modelos de execuções'} />}
          />
          <div className='divConteudo'>
            <ThemeProvider theme={getMuiTheme()}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                  <DesktopDatePicker
                    slotProps={{ textField: { size: 'small' } }}
                    maxDate={new Date()}
                    sx={{ paddingLeft: '20px' }}
                    format='dd/MM/yyyy'
                    label="Inicio"
                    value={inicio as Date}
                    onChange={(newValue) => setInicio(new Date(newValue?.setHours(0, 0, 0, 0) as number))}
                  />
                </LocalizationProvider>&nbsp;
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                  <DesktopDatePicker
                    format='dd/MM/yyyy'
                    label="Final"
                    value={final}
                    minDate={inicio as Date}
                    maxDate={new Date()}
                    onChange={(newValue) => setFinal(new Date(newValue?.setHours(23, 59, 59, 59) as number))}
                  />
                </LocalizationProvider>
              </div>
            </ThemeProvider>
            <button
              onClick={() => mutate(modelo?.value as string)}
              disabled={!modelo || ((final as Date) < (inicio as Date))}
              className='button is-success'
            >
              Filtrar&nbsp;
              <IoFilterSharp />
            </button>
          </div>
        </>
      }
      {
        ((final as Date) < (inicio as Date)) &&
        <Typography variant='subtitle2'>Data final não pode ser menor do que a data inicio</Typography>
      }
      <FilledAccordeon
        modelo={modelo}
        execucoes={execucoes as any}
        isLoading={isLoading}
      />
    </>
  )
}