import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { MainContainerAvaliation } from "./Avaliacao/AvaliacaoStyle";
import { Container } from "./Unique/UniqueStyle";
import { SubItemProps } from "./Avaliacao/Avaliacao";
import SubItemComentario from "./SubItemComentario";

export default function DateFormat({ subItem, indexItem, onChange }: SubItemProps) {
  const [ocorrencia, setOcorrencia] = useState<number | null>(subItem.padrao ? subItem.padrao : null);

  useEffect(() => {
    if (subItem.padrao) {
      subItem.ocorrencia = subItem.padrao
    }
  }, [])

  useEffect(() => {
    onChange && onChange()
  }, [ocorrencia])

  return (
    <MainContainerAvaliation>
      <Container style={{ justifyContent: 'center !important' }}>
        <ReactDatePicker
          showMonthDropdown
          showYearDropdown
          // minDate={new Date()}
          portalId="root-portal"
          selected={ocorrencia as unknown as Date}
          onChange={(date: Date) => {
            setOcorrencia(new Date(date).getTime());
            subItem.ocorrencia = new Date(date).getTime();
          }}
          locale="pt"
          dateFormat='dd/MM/yyyy'
          onKeyDown={(e) => e.preventDefault()}
          wrapperClassName="inputDateStyle"
          className={`${subItem.obrigatoriedade?.preenchimento ? 'inputDate-invalid input' : 'inputDate input'}`}
          required
        />
        {subItem.ocorrencia && subItem.comentario &&
          <SubItemComentario subItem={subItem} indexItem={indexItem} onChange={onChange} />}
      </Container>
    </MainContainerAvaliation>
  )
}