import { Form, Label, Button, Input } from "../../StyledComponents/Input/generic";
import { useState, useContext, useMemo } from "react";
import { UserContext } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { TagContainer, TagSpan } from "../../StyledComponents/Modal/generic";
import { AiOutlineClose } from "react-icons/ai";
import AttToken from "../../helpers/attToken";
import { userBackObj } from "../Users/UserSearch";
import Teams from "../../service/Teams";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import queryClient from "../../service/query";
import { Autocomplete, TextField } from "@mui/material";

export type team = {
  dataAlteracao: number,
  dataCadastro: number,
  empresa: string,
  gerente: string,
  listaCliente: string[],
  nome: string,
  status: boolean,
  users: string[],
  teamID: string
}

export default function TeamRegister() {
  const { userList, setTeamList, teamList } = useContext(UserContext);
  const [teamName, setTeamName] = useState("");
  const { t } = useTranslation('translation');
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [disable, setDisable] = useState(true)

  const TeamsService = useMemo(() => new Teams(), []);

  const { mutate: registerTeam } = useMutation({
    mutationKey: ['createTeam'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await TeamsService.createTeam(token, teamName, selectedUsers, selectedServices)
        setTeamList([...teamList, response.team])
        queryClient.resetQueries(['TeamList'])
      }
    },
    onSuccess: () => {
      setTeamName('');
      setSelectedUsers([])
      setSelectedServices([])
      toast.success(t<string>('teamManage.registered.toastSuccess'));

    },
    onError: () => console.log(AxiosError)
  })

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();
      registerTeam()
      setDisable(true)
    }}>

      <ToastContainer />
      <TextField 
      label={t('teamManage.register.teamName')}
        fullWidth
        inputProps={{maxLength:80}}
        type="text"
        value={teamName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTeamName(e.target.value)
          setDisable(false);
        }}
        style={{marginBottom:'20px'}}
      />
      <Autocomplete
        multiple
        id="combo-box-demo"
        sx={{ marginTop: '15px' }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        filterSelectedOptions
        disableClearable
        options={
          (userList as userBackObj[])
            ?.sort((a: userBackObj, b: userBackObj) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
            ?.filter((e: userBackObj) => e.status === 'ativo')
            ?.map((ele: userBackObj) => ({
              label: ele.nome,
              value: ele.id,
            }))}
        onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
          const value = details?.option.value;
          if (selectedUsers.includes(value)) {
            setSelectedUsers(selectedUsers.filter(el => el !== value))
          } else setSelectedUsers([...selectedUsers, value])
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.value}>
            <span>{option.label}</span>
          </li>)}
        renderInput={(params) => <TextField {...params} label={t('Services.servicesManagement.members')} />}
      />
      <Button
        className="button is-success is-small"
        style={{ width: "100px" }}
        type="submit"
        disabled={teamName === '' || selectedUsers.length < 1 || disable}
      >
        {t('save')}
      </Button>
    </Form>
  )
}
