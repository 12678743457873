import { GenericHeader, TagContainer } from "../../StyledComponents/Modal/generic"
import { BoldText, GenericText } from "./style/RegisteredTeamsStyle"
import { useTranslation } from "react-i18next";
import { Label, Input } from "../../StyledComponents/Input/generic";
import { AiOutlineClose } from "react-icons/ai";
import Teams, { NotificaSobre, Team, TreatmentTypes } from "../../service/Teams";
import { useContext, useMemo, useState } from "react";
import { UserContext } from "../../context/UserContext";
import AttToken from "../../helpers/attToken";
import { toast } from 'react-toastify';
import queryClient from "../../service/query";
import TypePlacesTeamModal from "./TypePlacesTeamModal";
import { useMutation } from "@tanstack/react-query";
import TypePlaceTreatment from "./TypePlaceTreatment";
import TypePlaceNotification from "./TypePlaceNotification";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Autocomplete, TextField } from "@mui/material";
import { Accordion, AccordionSummary } from "@material-ui/core";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import './styles.css'
import { userBackObj } from "../Users/UserSearch";

export default function ChangeTeamModal({
  team,
  teamId,
  getInfo,
}:
  {
    team: Team,
    teamId: string,
    setIsTab: Function,
    isLoadingById: boolean,
    getInfo: Function,
  }) {
  const { openModal, popUp, serviceTypesList, userList, escape, setChanged } = useContext(UserContext);
  const [teamName, setTeamName] = useState(team?.nome);
  const [selectedServices, setSelectedServices] = useState<string[]>(team?.tiposServico || []);
  const [selectedUsers, setSelectedUsers] = useState<string[]>(Object.keys(team?.users || []));
  const [status, setStatus] = useState(team?.status);
  const [tiposAtendimento, setTiposAtendimento] = useState(team?.tiposAtendimento || []);
  const [selectedTipoAtendimento, setSelectedTipoAtendimento] = useState('');
  const [notificadoSobre, setNotifcadoSobre] = useState(team?.notificaSobre || []);
  const [selectedTipoNotifica, setSelectedTipoNotifica] = useState('');
  const [showingAssociationNotifica, setShowingAssociationNotifica] = useState(false);
  const [showingAssociationAtendimento, setShowingAssociationAtendimento] = useState(false);
  const [showingAssociation, setShowingAssociation] = useState(false);
  const [tipoSelecionado, setTipoSelecionado] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  const TeamsService = useMemo(() => new Teams(), []);

  const { t } = useTranslation('translation');

  const { mutate } = useMutation({
    mutationKey: ['changeTeam'],
    mutationFn: async () => {
      setDisabled(true)
      toast.warn('Alterando equipe, aguarde até o modal se fechar')
      const token = await AttToken()
      if (token) {
        const equipe = {
          nome: teamName,
          tiposServico: selectedServices,
          status: status,
          users: selectedUsers,
          tiposLocaisAssocia: team.tiposLocaisAssocia,
          tiposAtendimento: team.tiposAtendimento,
          notificaSobre: team.notificaSobre
        }
        await TeamsService.updateTeam(token, teamId, equipe);
      }
    },
    onSuccess: () => {
      openModal();
      queryClient.refetchQueries(['TeamList'])
      getInfo(teamId)
      toast.success('Equipe alterada!')
      setDisabled(false)
    },
    onError: () => {
      toast.error('Erro ao alterar equipe')
    }
  })

  const findServiceName = (value: string) => {
    const serviceName = serviceTypesList.find((el) => el.tipoId === value);
    if (serviceName) return serviceName.nome
    else return 'Serviço não encontrado';
  }

  return (
    <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
      <div className="modal-background" onKeyDown={() => escape()} onClick={() => openModal()} />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>
            {t('teamManage.registered.edit')}
          </GenericHeader>
          <GenericText>
            {t('teamManage.registered.beConf')}
          </GenericText>
        </header>
        <div className="modal-card-body">
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
            onSubmit={(e) => e.preventDefault()}
          >
            {showingAssociation &&
              <TypePlacesTeamModal
                tipoServico={tipoSelecionado}
                tipoLocalAssocia={team.tiposLocaisAssocia || null}
                setShowingAssociation={setShowingAssociation}
                nomeServico={findServiceName(tipoSelecionado)}
                equipe={team}
              />}
            {showingAssociationAtendimento &&
              <TypePlaceTreatment
                tipoServico={selectedTipoAtendimento}
                tiposAtendimento={team.tiposAtendimento || null}
                setShowingAssociation={setShowingAssociationAtendimento}
                nomeServico={findServiceName(selectedTipoAtendimento)}
                equipe={team}
              />}
            {showingAssociationNotifica &&
              <TypePlaceNotification
                tipoServico={selectedTipoNotifica}
                notificaSobre={team.notificaSobre || null}
                setShowingAssociation={setShowingAssociationNotifica}
                nomeServico={findServiceName(selectedTipoNotifica)}
                equipe={team}
              />}
            {!showingAssociation && !showingAssociationAtendimento && !showingAssociationNotifica && <>
              <TextField
                label={t('team')}
                fullWidth
                style={{ marginBottom: '20px' }}
                value={teamName}
                inputProps={{ maxLength: 80 }}
                onChange={(e) => setTeamName(e.target.value)}
              />
              <Accordion style={{ padding: '0px 15px 10px 15px' }} expanded={expanded === 'panel1'}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon
                    onClick={(isExpanded) => {
                      if (expanded === 'panel1') {
                        handleChange(!isExpanded, '')
                      } else handleChange(true, 'panel1')
                    }}
                  />}>
                  <Label>{t('Services.servicesManagement.kind')}</Label>
                </AccordionSummary>
                <TagContainer>
                  {team?.tiposLocaisAssocia && team?.tiposLocaisAssocia?.length > 0 && team?.tiposLocaisAssocia?.map((e, i: number) => {
                    return selectedServices.includes(e.tipo) ? (
                      <div key={i} className='tagColumns'>
                        <span
                          className="tag column"
                          style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 5px 0px 5px' }}
                          key={`INDEX${i}`} onClick={() => {
                            setSelectedServices(selectedServices.filter(el => el !== e.tipo))
                            if (team.tiposLocaisAssocia) team.tiposLocaisAssocia = team.tiposLocaisAssocia.filter(el => el.tipo !== e.tipo)
                          }}>
                          {findServiceName(e.tipo)}
                          <AiOutlineClose />
                        </span>
                        &nbsp;
                        <div className='column' style={{ margin: 0, padding: 0 }}>
                          <button
                            className="button is-success is-small"
                            onClick={() => {
                              setTipoSelecionado(e.tipo)
                              setShowingAssociation(true);
                            }}
                          >
                            {t('Services.servicesManagement.locaisTrabalho')}
                            &nbsp;<ArrowForwardIcon />
                          </button>
                        </div>
                      </div>
                    ) : <></>
                  })}
                </TagContainer>
                <Autocomplete
                  multiple
                  value={selectedServices?.map((ele) => (
                    {
                      value: ele,
                      label: serviceTypesList?.find((elem) => elem.tipoId === ele)?.nome
                    }
                  )) || null}
                  id="combo-box-demo"
                  sx={{ marginTop: '15px' }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  filterSelectedOptions
                  disableClearable
                  options={
                    serviceTypesList
                      ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                      ?.map((ele: any) => ({
                        label: ele.nome,
                        value: ele.tipoId,
                      }))}
                  onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                    const value = details?.option.value;
                    if (selectedServices?.includes(value)) {
                      setSelectedServices((prevState) => prevState ? prevState.filter(el => el !== details.option.value) : [])
                    } else {
                      if (team.tiposLocaisAssocia) team.tiposLocaisAssocia.push({ tipo: value, conjuntosAssociados: null, cnjs: [] });
                      else team.tiposLocaisAssocia = [{ tipo: value, conjuntosAssociados: null, cnjs: [] }];
                      setSelectedServices([...selectedServices, value])
                    }
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                      <span>{option.label}</span>
                    </li>)}
                  renderInput={(params) => <TextField {...params} label={'Tipos de serviço'} />}
                />
              </Accordion>
              <Accordion style={{ padding: '0px 15px 10px 15px' }} expanded={expanded === 'panel2'}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon
                    onClick={(isExpanded) => {
                      if (expanded === 'panel2') {
                        handleChange(!isExpanded, '')
                      } else handleChange(true, 'panel2')
                    }}
                  />}>
                  <Label>{t('Services.servicesManagement.AtenderDe')}</Label>
                </AccordionSummary>
                <TagContainer>
                  {team?.tiposAtendimento && team?.tiposAtendimento?.length > 0 && team?.tiposAtendimento?.map((e, i: number) => (
                    <div key={i} className='columns tagColumns'>
                      <span
                        className="tag column"
                        style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 5px 0px 5px' }}
                        key={`INDEX${i}`}
                        onClick={() => {
                          setTiposAtendimento(prev => prev ? prev.filter(el => el.tipo !== e.tipo) : []);
                          if (team.tiposAtendimento) team.tiposAtendimento = team.tiposAtendimento.filter(el => el.tipo !== e.tipo)
                        }}>
                        {findServiceName(e.tipo)}
                        <AiOutlineClose />
                      </span>
                      &nbsp;
                      <div className='column' style={{ margin: 0, padding: 0 }}>
                        <button
                          className="button is-success is-small"
                          onClick={() => {
                            setSelectedTipoAtendimento(e.tipo)
                            setShowingAssociationAtendimento(true);
                          }}
                        >
                          {t('Services.servicesManagement.locaisAtendimento')}&nbsp;<ArrowForwardIcon />
                        </button>
                      </div>
                    </div>
                  ))}
                </TagContainer>
                <Autocomplete
                  multiple
                  value={tiposAtendimento?.map((ele) => (
                    {
                      value: ele.tipo,
                      label: serviceTypesList?.find((elem: any) => elem.tipoId === ele.tipo)?.nome
                    }
                  )) || null}
                  id="combo-box-demo"
                  sx={{ marginTop: '15px' }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  filterSelectedOptions
                  disableClearable
                  options={
                    serviceTypesList
                      ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                      ?.map((ele: any) => ({
                        label: ele.nome,
                        value: ele.tipoId,
                      }))}
                  onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                    const value = details?.option.value;
                    const objToPush: TreatmentTypes = { tipo: value, prioridade: ['baixa'], cnjs: [] }
                    if (tiposAtendimento.some(el => el.tipo === objToPush.tipo)) {
                      setTiposAtendimento(prev => prev ? prev.filter(el => el.tipo !== objToPush.tipo) : []);
                      if (team.tiposAtendimento) { team.tiposAtendimento = team.tiposAtendimento.filter(el => el.tipo !== objToPush.tipo) }
                    } else {
                      if (team.tiposAtendimento) team.tiposAtendimento.push(objToPush);
                      else team.tiposAtendimento = [objToPush];
                      setTiposAtendimento(prev => prev ? [...prev, objToPush] : [objToPush])
                    }
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                      <span>{option.label}</span>
                    </li>)}
                  renderInput={(params) => <TextField {...params} label={'Tipos de serviço'} />}
                />
              </Accordion>
              <Accordion style={{ padding: '0px 15px 10px 15px' }} expanded={expanded === 'panel3'}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon
                    onClick={(isExpanded) => {
                      if (expanded === 'panel3') {
                        handleChange(!isExpanded, '')
                      } else handleChange(true, 'panel3')
                    }}
                  />}>
                  <Label>{t('Services.servicesManagement.serNotificado')}</Label>
                </AccordionSummary>
                <TagContainer>
                  {team?.notificaSobre && team?.notificaSobre?.length > 0 && team?.notificaSobre?.map((e, i: number) => (
                    <div key={i} className='columns tagColumns'>
                      <span
                        className="tag column"
                        style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 5px 0px 5px' }}
                        key={`INDEX${i}`}
                        onClick={() => {
                          setNotifcadoSobre(prev => prev ? prev.filter(el => el.tipo !== e.tipo) : []);
                          if (team.notificaSobre) team.notificaSobre = team.notificaSobre.filter(el => el.tipo !== e.tipo)
                        }}>
                        {findServiceName(e.tipo)}
                        <AiOutlineClose />
                      </span>
                      &nbsp;
                      <div className='column' style={{ margin: 0, padding: 0 }}>
                        <button
                          className="button is-success is-small"
                          onClick={() => {
                            setSelectedTipoNotifica(e.tipo)
                            setShowingAssociationNotifica(true);
                          }}
                        >
                          {t('teamManage.register.locaisNotificados')}&nbsp;<ArrowForwardIcon />
                        </button>
                      </div>
                    </div>
                  ))}
                </TagContainer>
                <Autocomplete
                  multiple
                  value={notificadoSobre?.map((ele) => (
                    {
                      value: ele.tipo,
                      label: serviceTypesList?.find((elem: any) => elem.tipoId === ele.tipo)?.nome
                    }
                  )) || null}
                  id="combo-box-demo"
                  sx={{ marginTop: '15px' }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  filterSelectedOptions
                  disableClearable
                  options={
                    serviceTypesList
                      ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                      ?.map((ele: any) => ({
                        label: ele.nome,
                        value: ele.tipoId,
                      }))}
                  onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                    const value = details?.option.value;
                    const objToPush: NotificaSobre = { tipo: value, cnjs: [] }
                    if (notificadoSobre.some(el => el.tipo === objToPush.tipo)) {
                      setNotifcadoSobre(prev => prev ? prev.filter(el => el.tipo !== objToPush.tipo) : []);
                      if (team.notificaSobre) { team.notificaSobre = team.notificaSobre.filter(el => el.tipo !== objToPush.tipo) }
                    } else {
                      if (team.notificaSobre) team.notificaSobre.push(objToPush);
                      else team.notificaSobre = [objToPush];
                      setNotifcadoSobre(prev => prev ? [...prev, objToPush] : [objToPush])
                    }
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                      <span>{option.label}</span>
                    </li>)}
                  renderInput={(params) => <TextField {...params} label={'Tipos de serviço'} />}
                />
              </Accordion>
              <Autocomplete
                multiple
                value={selectedUsers && selectedUsers.map((ele: string) => (
                  {
                    value: ele,
                    label: String(userList?.find((elem: userBackObj) => elem.id === ele)?.nome)
                  }
                )) || null}
                id="combo-box-demo"
                sx={{ marginTop: '15px' }}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                filterSelectedOptions
                disableClearable
                options={
                  (userList as userBackObj[])
                    ?.sort((a: userBackObj, b: userBackObj) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.filter((e: userBackObj) => e.status === 'ativo')
                    ?.map((ele: userBackObj) => ({
                      label: ele.nome,
                      value: ele.id,
                    }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                  const value = details?.option.value;
                  if (selectedUsers.includes(value)) {
                    setSelectedUsers(selectedUsers.filter(el => el !== value))
                  } else setSelectedUsers([...selectedUsers, value])
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                renderInput={(params) => <TextField {...params} label={t('Services.servicesManagement.members')} />}
              />
              <Label>{t('status')}</Label>
              <select
                className='select is-small'
                onChange={(e) => setStatus(e.target.value === 'ativo' ? true : false)}
                defaultValue={status ? 'ativo' : 'inativo'}
              >
                <option value={'ativo'}>{t('active')}</option>
                <option value={'inativo'}>{t('inactive')}</option>
              </select>
            </>}
          </div>
        </div>
        <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            disabled={disabled}
            className="button is-success is-small"
            onClick={() => {
              localStorage.setItem('changedHirarquia', 'true');
              setChanged(true);
              mutate();
            }}
          >
            {t('save')}
          </button>
          <button
            type="submit"
            onClick={() => openModal()}
            className="button is-small is-warning"
          >
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}