import { Accordion, AccordionSummary, Tooltip } from '@material-ui/core'
import { RiDeleteBin5Line } from 'react-icons/ri';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { AiOutlineTool } from 'react-icons/ai';
import { ButtonsContainer, GraySpan, OptionsContainer, AddBtn, HeaderContainer, WeightInput, DivButtonContainerForm } from './FormStyle';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import Checklists, { ChecklistType } from '../../service/Checklists';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FormItem from './FormItem';
import AttToken from '../../helpers/attToken';
import { toast } from 'react-toastify';
import Loading from '../Loading/Loading';
import { types } from '../../service/ServiceTypes';
import { BsGear } from 'react-icons/bs';
import { useMutation } from '@tanstack/react-query';
import queryClient from '../../service/query';
import { StyleDivForm, StyledInputForm } from './Modals/FormModal/FormModalStyle';
import FormModal from './Modals/FormModal/FormModal';
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { UserContext } from '../../context/UserContext';
import checkEmptyValues from './helpers/checkEmptyValues';

type ChecklistProps = {
  form: ChecklistType,
  editing: { editing: boolean, id: string },
  setEditing: Function,
  formIndex: number,
  formKey: number,
  services: types[],
  refetchChecklists: Function,
  expanded: string | boolean,
  handleChange: Function,
  setLoadingSave: Function
}

const FormFisrtLevel = ({
  form,
  editing,
  setEditing,
  formIndex,
  formKey,
  services,
  refetchChecklists,
  expanded,
  handleChange,
  setLoadingSave
}:
  ChecklistProps
) => {

  const detectMaxValue = (form: ChecklistType) => {
    let maxValue = 1;
    form.itens.forEach(item => item.subitens.forEach(sub => {
      if (sub.tipo === 'avaliacao' && sub.avaliacao) {
        let higherAval = 0;
        sub.avaliacao.forEach(ava => {
          if (ava.valor > higherAval) higherAval = ava.valor;
        })
        if (higherAval > maxValue) maxValue = higherAval;
      }
    }))
    return maxValue
  }

  const { t, i18n } = useTranslation('translation');
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteSelection, setDeleteSelection] = useState('');
  const [clone, setClone] = useState(form.editavel ? form.editavel.clone : false);
  const [cancel, setCancel] = useState(form.editavel ? form.editavel.cancela : false);
  const [mandatory, setMandatory] = useState(form.editavel ? form.editavel.mandatory : false);
  const [openTicketInEx, setOpenTicketInEx] = useState(form.abreChamado ? form.abreChamado.durante : false)
  const [openTicketAfter, setOpenTicketAfter] = useState(form.abreChamado ? form.abreChamado.apos : false)
  const [selectedIdentifica, setSelectedIdentifica] = useState<string | undefined>(form.identificadorLista || '');
  const [lodingItem, setLoadingItem] = useState(false);
  const [editModal, setEditModal] = useState(-1);
  const [subModalOpen, setSubModalOpen] = useState(false);
  const [identificas, setIdentificas] = useState<{ value: string; nome: string; }[]>([])
  const [editingForm, setEditingForm] = useState<ChecklistType>({ ...form });
  const [expandedItem, setExpandedItem] = useState<string | boolean>(false);
  const [valorMaxScoring, setValorMaxScoring] = useState<number | string>(form.valorMaxScoring || detectMaxValue(form));

  const { identifiersByForm } = useContext(UserContext);

  const checklistService = useMemo(() => new Checklists(), []);

  useEffect(() => {
    const thisIdentifiers = identifiersByForm.find(e => e.formId === form.id);
    if (thisIdentifiers) {
      setIdentificas(thisIdentifiers.identifiersList)
      if (form.identificadorLista) {
        const thisValue = thisIdentifiers.identifiersList.find(e => e.value === form.identificadorLista);
        if (thisValue) {
          setSelectedIdentifica(thisValue.value);
        }
      }
    }
  }, [identifiersByForm, form.id, form.identificadorLista])

  const handleChangeItem = (isExpanded: boolean, panel: string) => {
    setExpandedItem(isExpanded ? panel : false);
  };

  useEffect(() => {
    setEditingForm(form);
  }, [form])

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const list = Array.from(editingForm.itens)
    const [reordered] = list.splice(result.source.index, 1)
    if (result.destination) {
      list.splice(result.destination.index, 0, reordered)
    }
    form.itens.forEach(it => it.subitens.forEach(sub => {
      if (sub.help && sub.help.foto) {
        sub.help.newPhoto = true;
      }
    }))
    setEditingForm({ ...form, itens: list })
  }

  const removeChecklist = useMutation({
    mutationKey: ['deleteChecklist'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const body = { ...token, form: deleteSelection };
        await checklistService.deleteChecklist(body)
      }
    },
    onSuccess: () => {
      setConfirmationModal(false)
      queryClient.resetQueries(['GET_CHECKLIST'])
      toast.success('Checklist removido!')
    },
  });

  const SelectIdentiRef = useRef<HTMLSelectElement>(null);

  const InputNameRef = useRef<HTMLInputElement>(null);

  const InputScoring = useRef<HTMLInputElement>(null);

  const updateForm = async (checklist: ChecklistType) => {
    await toast.promise(async () => {
      try {
        setLoadingSave(true);
        const token = await AttToken();
        if (token) {
          await checklistService.updateChecklist(token, checklist);
          await refetchChecklists();
        }
        return setLoadingSave(false);
      } catch (err) {
        return console.log(err)
      }
    }, {
      pending: 'Salvando alteração!',
      success: 'Alteraçao salva!',
      error: 'Erro!'
    })
  }

  const handleClone = () => {
    setClone(!clone);
  };

  const handleCancel = () => {
    setCancel(!cancel);
  };

  const handleMandatory = () => {
    setMandatory(!mandatory);
    if (form.editavel) {
      form.editavel.mandatory = !mandatory;
    } else form.editavel = {
      mandatory: !mandatory,
    }
  };

  const handleTicketInEx = () => {
    setOpenTicketInEx(!openTicketInEx);
    if (form.abreChamado) {
      form.abreChamado.durante = !openTicketInEx;
    } else form.abreChamado = {
      durante: !openTicketInEx,
      apos: false
    }
  };

  const handleTicketAfter = () => {
    setOpenTicketAfter(!openTicketAfter);
    if (form.abreChamado) {
      form.abreChamado.apos = !openTicketAfter;
    } else form.abreChamado = {
      apos: !openTicketAfter,
      durante: false,
    }
  };

  const duplicateForm = useCallback(async () => {
    try {
      const token = await AttToken();
      if (token) {
        const body = { ...token, formId: form.id }
        await checklistService.copyChecklist(body);
        toast.success("Checklist duplicado!")
        queryClient.resetQueries(['checklist'])
      }
    } catch (err) {
      return console.log(err)
    }
  }, [checklistService, form.id])

  const addNewItem = async () => {
    setEditingForm({
      ...editingForm,
      itens: [
        ...editingForm.itens,
        {
          id: `${form.id}|${form.versao + 1}|${editingForm.itens.length}`,
          nome: 'Novo Item',
          peso: 1,
          subitens: [{
            id: form.itens[editingForm?.itens?.length - 1]?.subitens?.length - 1,
            nome: 'Subitem',
            itemid: `${form.id}|${form.versao + 1}|${form.itens.length}`,
            tipo: 'avaliacao',
            avaliacao: [{ nome: '1', valor: 0 }, { nome: '2', valor: valorMaxScoring }],
            peso: 1,
            threshold: -1
          }]
        }
      ]
    })
  };

  const findTypeName = (key: string) => {
    const service = services ? services.find((el) => el.tipoId === key) : false
    return service ? service.nome : ''
  }

  const TextInput = () => {
    const [name, setName] = useState(form.nome);
    const onChangeInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        form.nome = e.target.value;
      },
      []
    );
    return (
      <>
        {!editing.editing ?
          <StyleDivForm style={{
            color: '#2ea689',
            fontSize: '20px',
            fontWeight: 700,
            margin: 0,
            padding: 0,
            textAlign: 'initial',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
          }}>
            {name}
          </StyleDivForm> :
          <StyledInputForm
            className='is-fullwidth'
            type="text"
            onChange={onChangeInput}
            value={name}
            disabled={!(editing.editing && editing.id === form.id)}
            ref={InputNameRef}
          />
        }
      </>
    );
  }

  return (
    <div style={{ display: 'flex', minWidth: '100%' }} key={formKey}>
      {confirmationModal &&
        <ConfirmationModal
          yesFunction={() => removeChecklist.mutate()}
          isOpen={confirmationModal}
          closeFunction={setConfirmationModal}
        />}
      {editModal === formIndex &&
        <FormModal
          editModal={editModal}
          setEditModal={setEditModal}
          form={form}
          formIndex={formIndex}
          typeExists={
            form.tipoServico ? findTypeName(form.tipoServico) ? true : false : true
          }
          refetchChecklists={refetchChecklists}
        />}
      <Accordion
        style={{
          marginBottom: '30px',
          minWidth: '100%',
          maxWidth: '100%'
        }}
        expanded={expanded === form.id}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={(isExpanded) => {
            if (expanded === form.id) {
              handleChange(!isExpanded, '')
            } else
              handleChange(isExpanded, form.id)
          }} />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <div className='columns' style={{ display: 'flex', minWidth: '100%' }}>
            <div className='column is-three-fifths'>
              <TextInput />
              <div style={{ marginBottom: '10px', display: 'flex' }}>
                <span style={{ color: '#bdbebf' }}>{t('checklists.checklists.createdBy')}</span>&nbsp;
                <GraySpan>{(editing.editing && editing.id === form.id) ? editingForm.userNome : form.userNome}</GraySpan>&nbsp;
                <span style={{ color: '#bdbebf' }}>{` ${t('checklists.checklists.at')} 
              ${new Date(form.dataCadastro).toLocaleDateString('pt-br')} - ${t('checklists.checklists.version')} 
              ${form.versao}`}
                </span>
              </div>
              <ul>
                <li style={{ marginBottom: '10px' }}>
                  <label style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <GraySpan>{t('checklists.checklists.identifier')}</GraySpan>&nbsp;
                    {identificas.length ? (
                      <select
                        ref={SelectIdentiRef}
                        disabled={!(editing.editing && editing.id === form.id)}
                        className='select is-success is-small'
                        style={{ maxWidth: '150px', minWidth: '150px' }}
                        value={selectedIdentifica}
                        onChange={(e) => {
                          setSelectedIdentifica(e.target.value);
                          form.identificadorLista = e.target.value;
                        }}
                      >
                        <option value="" disabled>{`${t('select')}...`}</option>
                        {
                          identificas.map((e) => (
                            <option value={e.value} key={e.value}>{e.nome}</option>
                          ))
                        }
                      </select>
                    ) : (
                      <span className='tag is-warning'>Nenhuma Opção de identificador</span>
                    )}
                  </label>
                </li>
              </ul>
              <ul>
                <li style={{ marginBottom: '10px', display: 'flex' }}>
                  <GraySpan>{t('formDetails.Tipo_de_Servico')}:</GraySpan>&nbsp;
                  <span>{form.tipoServico ? findTypeName(form.tipoServico) || t('formDetails.Tipo_nao_encontrado') : t('formDetails.Sem_tipo_vinculado')}</span>
                </li>
                <li style={{ marginBottom: '10px', display: 'flex' }}>
                  <span>{form.preenchimentoWeb ? t('formDetails.Forumlario_de_Preenchimento_Web') : t('formDetails.Formulario_de_Preenchimento_de_Campo')}</span>
                </li>
                <li style={{ marginBottom: '10px', display: 'flex' }}>
                  <span>{form.preenchimentoWeb ? form.preenchimentoDeslogado ? t('formDetails.Formulario_Anonimo') : t('formDetails.Precisa_Estar_Logado_Para_Preencher') : ''}</span>
                </li>
                <li style={{ marginBottom: '10px', display: 'flex' }}>
                  <label>
                    Valor Max Para Scoring:
                    <WeightInput
                      type="text"
                      value={valorMaxScoring}
                      ref={InputScoring}
                      disabled={!(editing.editing && editing.id === form.id)}
                      onBlur={() => {
                        if (InputScoring && InputScoring.current) {
                          const val = Number(InputScoring.current.value);
                          if (isNaN(val) || val === 0) {
                            toast.warning('Valor max nao pode estar vazio ou ser 0')
                            setValorMaxScoring(100);
                            form.valorMaxScoring = 100;
                          } else {
                            setValorMaxScoring(val);
                            form.valorMaxScoring = val;
                          }
                        }
                      }}
                      onChange={(ev) => {
                        if (!isNaN(Number(ev.target.value)) || !ev.target.value) {
                          setValorMaxScoring(!ev.target.value ? '' : Number(ev.target.value))
                        }
                      }}
                    />
                  </label>
                </li>
              </ul>
            </div>
            <DivButtonContainerForm className='column'>
              <ButtonsContainer>
                <div style={{ marginRight: '8px' }}>
                  <button
                    className="button is-success"
                    style={{ marginTop: '5px' }}
                    onClick={duplicateForm}
                  >
                    {t('checklists.checklists.clone')}
                  </button>
                </div>
                <div style={{ marginLeft: '15px', marginBottom: '6px' }}>
                  <Tooltip title={`${t('settings')} ${t('notifications.unread.form')}`}>
                    <button
                      className="button is-small is-primary"
                      style={{ height: '50px', width: '60px', margin: '5px' }}
                      onClick={() => {
                        setEditModal(formIndex)
                      }}
                    >
                      <AiOutlineTool size={40} />
                    </button>
                  </Tooltip>
                  <Tooltip title={`${t('delete')} ${t('notifications.unread.form')}`}>
                    <button
                      id={form.id}
                      style={{ height: '50px', width: '60px', margin: '5px' }}
                      onClick={() => {
                        setDeleteSelection(form.id);
                        setConfirmationModal(true);
                      }}
                      className="button is-small is-danger"
                    >
                      <RiDeleteBin5Line size={40} />
                    </button>
                  </Tooltip>
                </div>
              </ButtonsContainer>
              <OptionsContainer>
                {!form.preenchimentoWeb &&
                  <label
                    style={{
                      textAlign: 'initial',
                      wordWrap: 'break-word',
                      wordBreak: 'break-word',
                      //  whiteSpace: 'normal',
                    }} htmlFor={`clone${'e.id'}`} className="checkbox">
                    <input
                      disabled={!(editing.editing && editing.id === form.id)}
                      type="checkbox"
                      style={{ marginRight: '4px' }}
                      checked={clone}
                      onChange={handleClone}
                    />
                    {`${t('checklists.checklists.makeClonable')}`}
                    {i18n.resolvedLanguage === 'en' && (
                      <span style={{ marginLeft: '63px', height: 0, width: 0 }} />
                    )}
                    {i18n.resolvedLanguage === 'es' && (
                      <span style={{ marginLeft: '60px', height: 0, width: 0 }} />
                    )}
                  </label>}
                {!form.preenchimentoWeb && <label style={{
                  textAlign: 'initial',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  // whiteSpace: 'normal',
                }} htmlFor={`cancel${form.id}`} className="checkbox">
                  <input
                    disabled={!(editing.editing && editing.id === form.id)}
                    type="checkbox"
                    style={{ marginRight: '4px' }}
                    checked={cancel}
                    onChange={handleCancel}
                  />
                  {t('checklists.checklists.allowCancel')}
                </label>}
                <label style={{
                  textAlign: 'initial',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  // whiteSpace: 'normal',
                }} htmlFor={`cancel${form.id}`} className="checkbox">
                  <input
                    disabled={!(editing.editing && editing.id === form.id)}
                    type="checkbox"
                    style={{ marginRight: '4px' }}
                    checked={mandatory}
                    onChange={handleMandatory}
                  />
                  {t('formDetails.Comentário_obrigatorio')}
                </label>
                {/* {
                  form.preenchimentoDeslogado && form.preenchimentoWeb && (
                    <>
                      <label style={{
                        textAlign: 'initial',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        // whiteSpace: 'normal',
                      }} htmlFor={`cancel${form.id}`} className="checkbox">
                        <input
                          disabled={!(editing.editing && editing.id === form.id)}
                          type="checkbox"
                          style={{ marginRight: '4px' }}
                          checked={openTicketAfter}
                          onChange={handleTicketAfter}
                        />
                        Permitir Abertura de Chamado Após o Preenchimento
                      </label>
                      <label style={{
                        textAlign: 'initial',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        //whiteSpace: 'normal',
                      }} htmlFor={`cancel${form.id}`} className="checkbox">
                        <input
                          disabled={!(editing.editing && editing.id === form.id)}
                          type="checkbox"
                          style={{ marginRight: '4px' }}
                          checked={openTicketInEx}
                          onChange={handleTicketInEx}
                        />
                        Permitir Abertura de Chamado Durante o Preenchimento
                      </label>
                    </>
                  )
                } */}
                <div>
                  {!(editing.editing && editing.id === form.id) ? (
                    <button className='button is-light' type="button" onClick={() => {
                      setEditing({ editing: true, id: form.id })
                      setEditingForm({ ...form });
                    }}>{t('edit')}&nbsp;<BsGear /></button>
                  ) : (
                    <>
                      <button className='button is-success is-small' style={{ marginRight: '.5em' }} type="button" onClick={() => {
                        const objToSend: ChecklistType = {
                          ...editingForm,
                          valorMaxScoring: Number(valorMaxScoring),
                          nome: InputNameRef.current ? InputNameRef.current.value : form.nome,
                          editavel: {
                            cancela: cancel,
                            clone,
                            mandatory,
                          },
                          dataAlteracao: Date.now(),
                          versao: editingForm.versao + 1,
                          identificadorLista: selectedIdentifica ? selectedIdentifica : form?.identificadorLista,
                          abreChamado: form.abreChamado
                        }
                        const valid = checkEmptyValues(objToSend);
                        if (valid.success) {
                          setEditing({ editing: false, id: '' });
                          updateForm(objToSend)
                          form.nome = InputNameRef.current ? InputNameRef.current.value : form.nome;
                          queryClient.resetQueries(['GET_CHECKLIST'])
                        } else {
                          toast.warning(valid.problem)
                        }
                      }}>{t('save')}</button>
                      <button className='button is-danger is-small' type="button" onClick={() => {
                        setEditing({ editing: false, id: '' });
                        // if (checklistClone) {
                        //   setEditingForm(JSON.parse(checklistClone))
                        // }
                        queryClient.resetQueries(['GET_CHECKLIST'])
                      }}>{t('cancel')}</button>
                    </>
                  )}
                </div>
              </OptionsContainer>
            </DivButtonContainerForm>
          </div>
        </AccordionSummary>
        <AddBtn className="button is-primary is-light" onClick={addNewItem} disabled={!(editing.editing && editing.id === form.id)}>
          {t('checklists.checklists.addItem')}
        </AddBtn>
        <HeaderContainer className='columns' style={{ width: '100%', display: 'flex' }}>
          <span style={{ textAlign: 'center' }} className='column column is-1'>{t('checklists.checklists.item')}</span>
          <span style={{ textAlign: 'left' }} className='column name'></span>
          <span style={{ textAlign: 'center' }} className='column column is-one-quarter'>{t('options')}</span>
        </HeaderContainer>
        {lodingItem ? <Loading /> :
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="subItem" isDropDisabled={!(editing.editing && editing.id === form.id) || subModalOpen}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {editingForm.itens?.map((item, ind) => {
                    return (
                      <Draggable isDragDisabled={!(editing.editing && editing.id === form.id) || subModalOpen || typeof expandedItem === 'string'} key={String(item.id)} draggableId={String(item.id)} index={ind}>
                        {(provided) => (
                          <div key={ind} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <FormItem
                              expandedItem={expandedItem}
                              handleChangeItem={handleChangeItem}
                              isEditing={(editing.editing && editing.id === form.id)}
                              item={item}
                              setEditingForm={setEditingForm}
                              indexItem={ind}
                              formId={form.id}
                              setLoadingItem={setLoadingItem}
                              form={editingForm}
                              subModalOpen={subModalOpen}
                              setSubModalOpen={setSubModalOpen}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>)}
            </Droppable>
          </DragDropContext>}
      </Accordion>
    </div>
  )
}

export default FormFisrtLevel;