import { ChamadoChecklist, FormToFilling } from "./Types";

type returnType = {
  success: boolean;
  item: string | undefined;
  subItem: string | undefined;
  chamado?: boolean;
  texto?: string;
  ocorrencia?: string;
};

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function CheckMandatory(checklist: FormToFilling): returnType {
  let result: returnType = {
    success: true,
    item: "",
    subItem: "",
    chamado: false,
    texto: "",
  };
  checklist.itens.forEach((element) => {
    if (element.ocorrenciaMultipla && element.ocorrencias && element.ocorrencias.length) {
      element.ocorrencias.filter(oco => !oco.deleted).forEach(oco => {
        oco.subitens.forEach((el) => {
          if (el.chamadosConfig) {
            if (el.chamadosConfig.chamadoObrigatorio === "sempre" && (!el.chamados || el.chamados.length === 0)) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                chamado: true,
                texto: "",
                ocorrencia: oco.nome
              };
              return;
            }
          }
          if (el.chamados) {
            if (el.chamados.some((elem) => !elem.textChamado || !elem.textChamado?.length)) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `Chamado com mensagem ausente no item "${element.nome}", ocorrencia "${oco.nome}" ,subitem "${el.nome}"`,
                ocorrencia: oco.nome
              };
            }
            if (el.chamados.some((elem) => !elem.dateChamado || elem.dateChamado === undefined)) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `Chamado sem data no item"${element.nome}", ocorrencia "${oco.nome}" ,subitem "${el.nome}"`,
                ocorrencia: oco.nome
              };
            }
            return;
          }
          if (el.tipo === "assinatura") {
            if (el.obrigatoriedade?.preenchimento) {
              if (!el.assinatura || !el.assinatura.length) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  ocorrencia: oco.nome
                };
              } else if (el.assinatura && el?.assinatura?.length > 0) {
                el.checked = true;
              }
              return;
            }
            if (el.assinatura && el?.assinatura?.length > 0) {
              el.checked = true;
            }
            return;
          }
          if (el.tipo === "email") {
            if (el?.ocorrencia?.length > 0 && !regexEmail.test(el.ocorrencia)) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `Valor incorreto no item "${element.nome}", ocorrencia "${oco.nome}" ,subitem "${el.nome}"`,
                ocorrencia: oco.nome
              };
              return;
            }
            if (el.obrigatoriedade?.preenchimento) {
              if (!el?.ocorrencia?.length) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  texto: `Preenchimento obrigatório no item "${element.nome}", ocorrencia "${oco.nome}" ,subitem "${el.nome}"`,
                  ocorrencia: oco.nome
                };
                return;
              }
            }
          }
          if (el.tipo === "cpfcnpj") {
            if (el && el?.ocorrencia?.length > 0 && el?.ocorrencia?.length !== 18 && el?.ocorrencia?.length !== 14) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `Valor incorreto no item "${element.nome}", ocorrencia "${oco.nome}" ,subitem "${el.nome}"`,
                ocorrencia: oco.nome
              };
              return;
            }
          }
          if (el.tipo === "rg") {
            if (el && el?.ocorrencia?.length > 0 && el?.ocorrencia?.length !== 13) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `Valor incorreto no item "${element.nome}", ocorrencia "${oco.nome}" ,subitem "${el.nome}"`,
                ocorrencia: oco.nome
              };
              return;
            }
          }
          if (el.tipo === "placa") {
            if (el?.ocorrencia?.length > 0 && el?.ocorrencia?.length !== 8) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `Valor incorreto no item "${element.nome}", ocorrencia "${oco.nome}" ,subitem "${el.nome}"`,
                ocorrencia: oco.nome
              };
              return;
            }
          }
          if (el.tipo === "telefone") {
            if (el && el?.ocorrencia?.length > 0 && el?.ocorrencia?.length < 12) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `Valor incorreto no item "${element.nome}", ocorrencia "${oco.nome}" ,subitem "${el.nome}"`,
                ocorrencia: oco.nome
              };
              return;
            }
          }
          if (el.tipo === "cep") {
            if (el && el?.ocorrencia?.length > 0 && el?.ocorrencia?.length < 9) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `Valor incorreto no item "${element.nome}", ocorrencia "${oco.nome}" ,subitem "${el.nome}"`,
                ocorrencia: oco.nome
              };
              return;
            }
          }
          if (el.tipo === "numero") {
            if (el && el?.ocorrencia?.length > Number(el.maxNumberVal) && el?.ocorrencia?.length < Number(el.minNumberVal)) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `Valor incorreto no item "${element.nome}", ocorrencia "${oco.nome}" ,subitem "${el.nome}"`,
                ocorrencia: oco.nome
              };
              return;
            }
          }
          if (el.tipo !== "avaliacao" && el.tipo !== "email") {
            if (
              el.ocorrencia ||
              el.assinatura ||
              //el?.nota ||
              el?.selecionados?.some((e) => e === true) ||
              el?.selecionados?.some((e) => {
                const element: { texto: string } = e as {
                  texto: string;
                };
                return element?.texto?.length;
              })
            ) {
              if ((!el.img || el.img?.length === 0) && el?.obrigatoriedade?.img) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  ocorrencia: oco.nome
                };
                return;
              }
            }
          }
          if (el.tipo !== "avaliacao" && el.tipo !== "email") {
            if (
              el.ocorrencia ||
              el.assinatura ||
              //el?.nota ||
              el?.selecionados?.some((e) => e === true) ||
              el?.selecionados?.some((e) => {
                const element: { texto?: string } = e as {
                  texto?: string;
                };
                return element?.texto?.length;
              })
            ) {
              if ((!el.obs || el.obs.length <= 0) && el?.obrigatoriedade?.ocorrencia) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                  ocorrencia: oco.nome
                };
                return;
              }
            }
          }
          if (el.tipo === "avaliacao") {
            if (el.nota === undefined && el?.obrigatoriedade?.preenchimento) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                ocorrencia: oco.nome
              };
              return;
            }
            if (el.nota && typeof el.nota === "number") {
              return (el.checked = true);
            }
            let status: "conf" | "Nconf" | "NA" | "" = "";
            if ((el.nota || el.nota === 0) && (el.threshold || el.threshold === 0)) {
              if (el.nota === -1) status = "NA";
              if (el.nota <= el.threshold) status = "Nconf";
              if (el.nota > el.threshold) status = "conf";
            }
            switch (status) {
              case "NA":
                if (el.obrigatoriedade?.NA.img && (!el?.img || !el?.img?.length)) {
                  result = {
                    success: false,
                    item: element.nome,
                    subItem: el.nome,
                    ocorrencia: oco.nome
                  };
                  return;
                }
                if (el.obrigatoriedade?.NA.ocorrencia && (!el?.ocorrencia || !el?.ocorrencia?.length)) {
                  result = {
                    success: false,
                    item: element.nome,
                    subItem: el.nome,
                    ocorrencia: oco.nome
                  };
                  return;
                }
                break;
              case "Nconf":
                if (el.obrigatoriedade?.naoConforme.img && (!el.img || !el.img.length)) {
                  result = {
                    success: false,
                    item: element.nome,
                    subItem: el.nome,
                    ocorrencia: oco.nome
                  };
                  return;
                }
                if (el.obrigatoriedade?.naoConforme.ocorrencia && (!el.ocorrencia || !el.ocorrencia.length)) {
                  result = {
                    success: false,
                    item: element.nome,
                    subItem: el.nome,
                    ocorrencia: oco.nome
                  };
                  return;
                }
                break;
              case "conf":
                if (el.obrigatoriedade?.conforme.img && (!el.img || el.img.length === 0)) {
                  result = {
                    success: false,
                    item: element.nome,
                    subItem: el.nome,
                    ocorrencia: oco.nome
                  };
                  return;
                }
                if (el.obrigatoriedade?.conforme.ocorrencia && (!el.ocorrencia || !el.ocorrencia.length)) {
                  result = {
                    success: false,
                    item: element.nome,
                    subItem: el.nome,
                    ocorrencia: oco.nome
                  };
                  return;
                }
                break;
              default:
                break;
            }
            if (typeof el.nota === "number") return (el.checked = true);
          } else if (el.tipo === "multiple" || el.tipo === "unique") {
            if (el.obrigatoriedade?.preenchimento && (!el?.selecionados?.length || !el?.selecionados.some((e) => e === true))) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                ocorrencia: oco.nome
              };
    
              return;
            }
            if (el.selecionados && el.selecionados?.length > 0 && el.selecionados.some((e) => e)) el.checked = true;
          } else if (el.tipo === "list") {
            if (
              el.obrigatoriedade?.preenchimento &&
              el?.selecionados?.some((e) => {
                const element: { texto: string } = e as {
                  texto: string;
                };
                return element?.texto?.length === 0;
              })
            ) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                ocorrencia: oco.nome
              };
              return;
            }
            if (
              el.selecionados &&
              el.selecionados.length > 0 &&
              el?.selecionados?.some((e) => {
                const elem: { texto: string } = e as {
                  texto: string;
                };
                return elem?.texto?.length > 0;
              })
            )
              el.checked = true;
          } else {
            if (el.obrigatoriedade?.preenchimento && !el.ocorrencia) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                ocorrencia: oco.nome
              };
              return;
            }
            if (el.ocorrencia) el.checked = true;
          }
        });
      })
    } else {
      element.subitens.forEach((el) => {
        if (el.chamadosConfig) {
          if (el.chamadosConfig.chamadoObrigatorio === "sempre" && (!el.chamados || el.chamados.length === 0)) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              chamado: true,
              texto: "",
            };
            return;
          }
        }
        if (el.chamados) {
          if (el.chamados.some((elem) => !elem.textChamado || !elem.textChamado?.length)) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              texto: `Chamado com mensagem ausente no item "${element.nome}", subitem "${el.nome}"`,
            };
          }
          if (el.chamados.some((elem) => !elem.dateChamado || elem.dateChamado === undefined)) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              texto: `Chamado sem data no item"${element.nome}", subitem "${el.nome}"`,
            };
          }
          return;
        }
        if (el.tipo === "assinatura") {
          if (el.obrigatoriedade?.preenchimento) {
            if (!el.assinatura || !el.assinatura.length) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
              };
            } else if (el.assinatura && el?.assinatura?.length > 0) {
              el.checked = true;
            }
            return;
          }
          if (el.assinatura && el?.assinatura?.length > 0) {
            el.checked = true;
          }
          return;
        }
        if (el.tipo === "email") {
          if (el?.ocorrencia?.length > 0 && !regexEmail.test(el.ocorrencia)) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              texto: `Valor incorreto no item "${element.nome}", subitem "${el.nome}"`,
            };
            return;
          }
          if (el.obrigatoriedade?.preenchimento) {
            if (!el?.ocorrencia?.length) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
                texto: `Preenchimento obrigatório no item "${element.nome}", subitem "${el.nome}"`,
              };
              return;
            }
          }
        }
        if (el.tipo === "cpfcnpj") {
          if (el && el?.ocorrencia?.length > 0 && el?.ocorrencia?.length !== 18 && el?.ocorrencia?.length !== 14) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              texto: `Valor incorreto no item "${element.nome}", subitem "${el.nome}"`,
            };
            return;
          }
        }
        if (el.tipo === "rg") {
          if (el && el?.ocorrencia?.length > 0 && el?.ocorrencia?.length !== 13) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              texto: `Valor incorreto no item "${element.nome}", subitem "${el.nome}"`,
            };
            return;
          }
        }
        if (el.tipo === "placa") {
          if (el?.ocorrencia?.length > 0 && el?.ocorrencia?.length !== 8) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              texto: `Valor incorreto no item "${element.nome}", subitem "${el.nome}"`,
            };
            return;
          }
        }
        if (el.tipo === "telefone") {
          if (el && el?.ocorrencia?.length > 0 && el?.ocorrencia?.length < 12) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              texto: `Valor incorreto no item "${element.nome}", subitem "${el.nome}"`,
            };
            return;
          }
        }
        if (el.tipo === "cep") {
          if (el && el?.ocorrencia?.length > 0 && el?.ocorrencia?.length < 9) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              texto: `Valor incorreto no item "${element.nome}", subitem "${el.nome}"`,
            };
            return;
          }
        }
        if (el.tipo === "numero") {
          if (el && el?.ocorrencia?.length > Number(el.maxNumberVal) && el?.ocorrencia?.length < Number(el.minNumberVal)) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
              texto: `Valor incorreto no item "${element.nome}", subitem "${el.nome}"`,
            };
            return;
          }
        }
        if (el.tipo !== "avaliacao" && el.tipo !== "email") {
          if (
            el.ocorrencia ||
            el.assinatura ||
            //el?.nota ||
            el?.selecionados?.some((e) => e === true) ||
            el?.selecionados?.some((e) => {
              const element: { texto: string } = e as {
                texto: string;
              };
              return element?.texto?.length;
            })
          ) {
            if ((!el.img || el.img?.length === 0) && el?.obrigatoriedade?.img) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
              };
              return;
            }
          }
        }
        if (el.tipo !== "avaliacao" && el.tipo !== "email") {
          if (
            el.ocorrencia ||
            el.assinatura ||
            //el?.nota ||
            el?.selecionados?.some((e) => e === true) ||
            el?.selecionados?.some((e) => {
              const element: { texto?: string } = e as {
                texto?: string;
              };
              return element?.texto?.length;
            })
          ) {
            if ((!el.obs || el.obs.length <= 0) && el?.obrigatoriedade?.ocorrencia) {
              result = {
                success: false,
                item: element.nome,
                subItem: el.nome,
              };
              return;
            }
          }
        }
        if (el.tipo === "avaliacao") {
          if (el.nota === undefined && el?.obrigatoriedade?.preenchimento) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
            };
            return;
          }
          if (el.nota && typeof el.nota === "number") {
            return (el.checked = true);
          }
          let status: "conf" | "Nconf" | "NA" | "" = "";
          if ((el.nota || el.nota === 0) && (el.threshold || el.threshold === 0)) {
            if (el.nota === -1) status = "NA";
            if (el.nota <= el.threshold) status = "Nconf";
            if (el.nota > el.threshold) status = "conf";
          }
          switch (status) {
            case "NA":
              if (el.obrigatoriedade?.NA.img && (!el?.img || !el?.img?.length)) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                };
                return;
              }
              if (el.obrigatoriedade?.NA.ocorrencia && (!el?.ocorrencia || !el?.ocorrencia?.length)) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                };
                return;
              }
              break;
            case "Nconf":
              if (el.obrigatoriedade?.naoConforme.img && (!el.img || !el.img.length)) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                };
                return;
              }
              if (el.obrigatoriedade?.naoConforme.ocorrencia && (!el.ocorrencia || !el.ocorrencia.length)) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                };
                return;
              }
              break;
            case "conf":
              if (el.obrigatoriedade?.conforme.img && (!el.img || el.img.length === 0)) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                };
                return;
              }
              if (el.obrigatoriedade?.conforme.ocorrencia && (!el.ocorrencia || !el.ocorrencia.length)) {
                result = {
                  success: false,
                  item: element.nome,
                  subItem: el.nome,
                };
                return;
              }
              break;
            default:
              break;
          }
          if (typeof el.nota === "number") return (el.checked = true);
        } else if (el.tipo === "multiple" || el.tipo === "unique") {
          if (el.obrigatoriedade?.preenchimento && (!el?.selecionados?.length || !el?.selecionados.some((e) => e === true))) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
            };
  
            return;
          }
          if (el.selecionados && el.selecionados?.length > 0 && el.selecionados.some((e) => e)) el.checked = true;
        } else if (el.tipo === "list") {
          if (
            el.obrigatoriedade?.preenchimento &&
            el?.selecionados?.some((e) => {
              const element: { texto: string } = e as {
                texto: string;
              };
              return element?.texto?.length === 0;
            })
          ) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
            };
            return;
          }
          if (
            el.selecionados &&
            el.selecionados.length > 0 &&
            el?.selecionados?.some((e) => {
              const elem: { texto: string } = e as {
                texto: string;
              };
              return elem?.texto?.length > 0;
            })
          )
            el.checked = true;
        } else {
          if (el.obrigatoriedade?.preenchimento && !el.ocorrencia) {
            result = {
              success: false,
              item: element.nome,
              subItem: el.nome,
            };
            return;
          }
          if (el.ocorrencia) el.checked = true;
        }
      });
    }
  });
  if (checklist.chamadosConfig) {
    if (checklist.chamadosConfig.chamadoObrigatorio && (!checklist.chamados || !checklist.chamados?.length)) {
      return {
        success: false,
        item: "chamado",
        subItem: "",
      };
    }
    if (checklist.chamados && checklist?.chamados.some((elem: ChamadoChecklist) => !elem.textChamado || !elem.textChamado?.length)) {
      return {
        success: false,
        item: "",
        subItem: "",
        texto: `Chamado do formulário com mensagem ausente"`,
      };
    }
    if (checklist.chamados && checklist?.chamados.some((elem: ChamadoChecklist) => !elem.dateChamado || elem.dateChamado === undefined)) {
      return {
        success: false,
        item: "",
        subItem: "",
        texto: `Data obrigatória no chamado do formulário"`,
      };
    }
  }

  return result;
}
