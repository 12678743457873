import { Container } from "@material-ui/core";
import { HeaderText, MainPageContainer, PageContentContainer } from "../../StyledComponents/pages/pages";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { ButtonsContainer, Column, ContainerBase, Paragraph, ParagraphMsg } from "./style/SingleNotificationStyle";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { ChecklistReportType, ChecklistSubitemReport } from "../../service/Reports";
import { useMutation, useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import Tickets, { novaMensagem } from "../../service/Tickets";
import { FileInput2 } from "../Users/style/UserRegisterStyle";
import ModalDeny from "./ModalDeny";
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import ModalImagePreview from "../Reports/ModalDetailPrint/ModalImagePreview";
import { AxiosError } from "axios";
import ModalDetailPrint from "../Reports/ModalDetailPrint/ModalDetailPrint";
import queryClient from "../../service/query";
import ModalImageOnTicket from "./ModalImageOnTicket";
import RedirectModal from "./Modals/RedirectModal";
import { Skeleton } from "@mui/material";
import SmallBox from "../Skeletons/SmallBox";
import ImagesCmp from "../ImagesCmp";
import PicturePreview from "../PicturePreview";
import { ItemChecklist } from "../../service/Checklists";

export default function SingleNotification() {
  const { t } = useTranslation('translation');

  const params = useParams();

  const navigate = useNavigate();

  const ticketsService = useMemo(() => new Tickets(), []);

  const actualDate = new Date().getTime();

  const { setShow, hierarchyLabel, ticket, openModal, setTicket, setPopUp, userData } = useContext(UserContext);

  const [selected, setSelected] = useState<ChecklistReportType>();
  const [image, setImage] = useState<string>('');
  //const [images] = useState<string[]>([])
  const [open, setOpen] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);
  const [openRefuse, setOpenRefuse] = useState(false);
  const [openImgPreview, setOpenImgPreview] = useState(false);
  const [openRedirect, setOpenRedirect] = useState(false)
  const [imgPreview] = useState('')
  const [objectStatus, setObjectStatus] = useState<novaMensagem>({
    comentario: '',
    dataAcompanhamento: new Date(actualDate - (actualDate % 86400000) + 10800000),
    status: 'emandamento',
    referenciaFoto: ''
  })
  const [disable, setDisable] = useState(false);

  const item = ticket?.execucaoChecklist?.itens?.filter((item: ItemChecklist) => item.id === ticket.itemId)[0]
  const subitem = ticket?.execucaoChecklist?.itens?.find((item: ItemChecklist) => item.id === ticket.itemId)
    ?.subitens?.filter((subItem) => subItem.id === ticket?.subitemId)[0] as unknown as ChecklistSubitemReport

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['TicketById'],
    queryFn: async () => {
      try {
        const token = await AttToken()
        if (token && params) {
          const response = await ticketsService.getTicketById(token, String(params.id))
          return response
        }
      } catch (err) {
        console.log(err)
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
      return toast.error('Erro ao buscar notificações')
    }
  })

  useEffect(() => {
    if (data) {
      setTicket(data)
      setSelected(data.execucaoChecklist)
    }
  }, [data, setTicket])

  const verificaResponsavel = () => {
    if (ticket && ticket.responsavelNome && ticket.responsavelNome !== userData.userName) {
      return false
    } else return true
  }

  const closeAndAtt = async () => {
    setOpen(false);
  }

  const closeImgPreview = async () => {
    setOpenImgPreview(false);
  }

  const closeRedirectModal = async () => {
    setOpenRedirect(false);
  }

  const onHandleClick = () => {
    setPopUp(true);
    setOpenModalPrint(true)
  }

  const { mutate: remove } = useMutation({
    mutationKey: ['UpdateStatusChamado'],
    mutationFn: async (value: string) => {
      setDisable(true)
      const token = await AttToken()
      if (token && params) {
        const novaMensagemObj: novaMensagem = {
          comentario: objectStatus.comentario,
          dataAcompanhamento: objectStatus.dataAcompanhamento,
          status: value,
          referenciaFoto: image,
        }
        const response = await ticketsService.update(token, String(params.id), { novaMensagem: novaMensagemObj })
        toast.warn(`Chamado ${objectStatus.status === 'recusado' ? 'recusado' : 'cancelado'}`)
        openModal(false);
        refetch()
        return response
      }
    },
    onSuccess: () => {
      setDisable(false)
    }
  })

  const renderSwitch = (param: ChecklistSubitemReport) => {
    switch (param.tipo) {
      case 'multiple': case 'unique':
        return (
          <>
            {param.selecao ? param.selecao.map((e, i) => {
              if ((param.selecionados && param.selecionados[i])) {
                return (<li key={i} style={{
                  fontWeight: `${(param.selecionados && param.selecionados[i]) ? '900' : '250'}`,
                  marginLeft: `${(param.selecionados && param.selecionados[i]) ? '30px' : '2px'}`
                }}>
                  {e}
                </li>)
              } else return <></>
            }) : <li>a</li>}
            {param.img && (
              <ImagesCmp images={param.img} selected={selected as ChecklistReportType} />
            )}
            {param.ocorrencia && <p>{`Ocorrencia: ${param.ocorrencia}`}</p>}
          </>
        )

      case 'avaliacao':
        return (
          param.avaliacao && param.avaliacao.map((ele, i) => (
            <>
              <ol
                style={{
                  fontWeight: `${(param.avaliacao && param.avaliacao[i].valor === param.nota) ? '900' : '250'}`
                }}
              >{ele.valor} - {ele.nome}
              </ol>
            </>
          )
          )
        )

      case 'list':
        return (
          <>
            {param.padraoLista ? param.padraoLista.map((e, i) => {
              if ((param.selecionados && param.selecionados[i].texto === e)) {
                return (<li key={i} style={{
                  fontWeight: `${(param.selecionados && param.selecionados[i].texto === e) ? '900' : '250'}`,
                  marginLeft: `${(param.selecionados && param.selecionados[i].texto === e) ? '30px' : '2px'}`
                }}>
                  {e}
                </li>)
              } else return <></>
            }) : <li>a</li>}
            {param.ocorrencia && <p>{`Resposta: ${param.ocorrencia}`}</p>}
            {param.img && (
              <ImagesCmp images={param.img} selected={selected as ChecklistReportType} />
            )}
          </>
        )

      case 'data': case 'hora': case 'assinatura': case 'email': case 'telefone': case 'rg': case 'cpfcnpj': case 'cep': case 'placa':
        return (
          <>
            {param.ocorrencia && <p>{`Resposta: ${param.ocorrencia}`}</p>}
            {param.img && (
              <ImagesCmp images={param.img} selected={selected as ChecklistReportType} />
            )}
          </>
        )

      default:
        return (
          <div>
            {param.ocorrencia && <li>{`Resposta: ${param.ocorrencia}`}</li>}
            {param.img && (
              <ImagesCmp images={param.img} selected={selected as ChecklistReportType} />
            )}
          </div>
        )
    }
  }

  const { mutate: accept } = useMutation({
    mutationKey: ['UpdateStatusChamado'],
    mutationFn: async (value: string) => {
      setDisable(true)
      const token = await AttToken()
      if (token && params) {
        const novaMensagemObj: novaMensagem = {
          comentario: objectStatus.comentario,
          dataAcompanhamento: objectStatus.dataAcompanhamento,
          status: value,
          referenciaFoto: image,
        }
        const response = await ticketsService.update(token, String(params.id), { novaMensagem: novaMensagemObj })
        toast.warn(`Chamado ${objectStatus.status === 'emandamento' ? 'em andamento' : 'concluido'}`)
        setImage('')
        refetch()
        return response
      }
    },
    onSuccess: () => {
      setDisable(false)
    }
  })

  function getBase64(file: File, cb: (s: string) => void) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const renderImages = (File: FileList | null) => {
    if (File) {
      Array.from(File).map(e => getBase64(e, (i) => setImage(i)));
    }
  }

  const BasicTicket = ({ el }: { el: string }) => {
    const { ticket } = useContext(UserContext);
    return (
      <div className="column">
        <Paragraph>
          {ticket && ticket.historicoChamado ? ticket.historicoChamado[el].nomeUsuarioAcompanhamento : ''}:
          &nbsp;
          <span>
            {ticket && ticket.historicoChamado ? new Date(ticket.historicoChamado[el].data).toLocaleString() : ''}
          </span>
        </Paragraph>
        <Paragraph>
          {t('notifications.ticket.Data_de _atendimento')}:
          &nbsp;
          <span>
            {ticket && ticket.historicoChamado ? new Date(ticket.historicoChamado[el].dataMensagem).toLocaleString() : ''}
          </span>
        </Paragraph>
        <Paragraph>
          {t('notifications.ticket.Comentario')}&nbsp;<span>{ticket && ticket.historicoChamado ? ticket.historicoChamado[el].comentario : ''}</span>
        </Paragraph>
      </div>
    )
  }

  const getStatus = (value: string) => {
    switch (value) {
      case 'emandamento':
        return (
          ticket && ticket.historicoChamado ? Object.keys(ticket.historicoChamado).map((e) => (
            <>
              <div className="columns" style={{ display: "flex" }}>
                <BasicTicket el={e} />
                {ticket.historicoChamado && ticket.historicoChamado[e].foto && (
                  <PicturePreview el={e} />
                )}
              </div><hr />
            </>
          )) : <span style={{ fontStyle: 'italic' }}>{t('notifications.ticket.chamado_sem_msg')}</span>
        )
      case 'pendente':
        return (
          <>
            <span style={{ fontStyle: 'italic' }}>{t('notifications.ticket.chamado_pendente')}</span><hr />
          </>
        )
      case 'concluido':
        return (
          <div>
            {ticket && ticket.historicoChamado ? Object.keys(ticket.historicoChamado).map((e) => (
              <>
                <div className="columns">
                  <BasicTicket el={e} />
                  <div className="column">
                    {!!ticket && ticket.historicoChamado && ticket.historicoChamado[e].status === 'concluido' ?
                      <span className="tag is-success">{t('notifications.statusOk')}</span> :
                      <span className="tag is-info">{t('notifications.statusProgress')}</span>}
                  </div>
                  {ticket.historicoChamado && ticket.historicoChamado[e].foto && (
                    <PicturePreview el={e} />
                  )}
                </div><hr />
              </>
            )) : <span style={{ fontStyle: 'italic' }}>{t('notifications.ticket.chamado_sem_msg')}</span>}
          </div>
        )
      case 'recusado':
        return (
          <>
            <span style={{ fontStyle: 'italic' }}>{t('notifications.ticket.Chamado_recusado')}</span><hr />
          </>
        )
      case 'cancelado':
        return (
          <div>
            {ticket && ticket.historicoChamado ? Object.keys(ticket.historicoChamado).map((e) => (
              <>
                <div className="columns">
                  <BasicTicket el={e} />
                  <div className="column">
                    {!!ticket && ticket.historicoChamado && ticket.historicoChamado[e].status === 'cancelado' ?
                      <span className="tag is-danger">{t('notifications.ticket.canceled')}</span> :
                      <span className="tag is-info">{t('notifications.statusProgress')}</span>}
                  </div>
                  {ticket.historicoChamado && ticket.historicoChamado[e].foto && (
                    <PicturePreview el={e} />
                  )}
                </div><hr />
              </>
            )) : <span style={{ fontStyle: 'italic' }}>{t('notifications.ticket.chamado_sem_msg')}</span>}
          </div>
        )
      default:
        return (
          <></>
        )
    }
  }

  const tipoExibido = (value: string) => {
    switch (value) {
      case 'avaliacao':
        return '(Avaliação)'
      case 'multiple':
        return '(Multiplo)'
      case 'unique':
        return '(Unico)'
      case 'textoCurto':
        return '(Texto curto)'
      case 'textoLongo':
        return '(Texto longo)'
      case 'list':
        return '(Lista de textos)'
      case 'email':
        return '(E-mail)'
      case 'placa':
        return '(Placa)'
      case 'cpfcnpj':
        return '(CPF / CNPJ)'
      case 'cep':
        return '(CEP)'
      case 'assinatura':
        return '(Assinatura)'
      case 'rg':
        return '(RG)'
      case 'data':
        return '(Data)'
      case 'hora':
        return '(Hora)'
      case 'telefone':
        return '(Telefone)'
      case 'numero':
        return '(Numero)'
      default:
        return (<div>AAAAAAA</div>)
    }
  }

  return (
    <MainPageContainer>
      <div className="side-bar">
        <Sidebar />
      </div>
      <PageContentContainer>
        <Header />
        {isLoading || isFetching ? (
          <div
            style={{ marginTop: '.8em', display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <div
              style={{ display: 'flex', justifyContent: 'space-around', width: '100%', alignSelf: 'center', justifySelf: 'center' }}>
              <Skeleton width={'5svw'} height={'6svh'} />
              <Skeleton width={'25svw'} height={'6svh'} />
              <Skeleton width={'8svw'} height={'6svh'} />
            </div>
            <div style={{ height: '70svh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
              <SmallBox height="30svh" widht="82%" />
              <SmallBox height="5svh" widht="82%" />
              <SmallBox height="30svh" widht="82%" />
            </div>
          </div>
        ) : <Container>
          <HeaderText style={{ display: 'flex', justifyContent: 'space-around' }}>
            <button
              onClick={() => {
                setDisable(true)
                /* localStorage.setItem('chamadoPage', JSON.stringify('ativo')) */
                navigate(`/notificacaoWeb`)
                //localStorage.removeItem('chamado')
                queryClient.resetQueries(['TicketById'])
                setShow(false)
              }}
              className="button is-small">{t('ticketDetail.back')}</button>
            {t('ticketDetail.detailHeader')} - {ticket && ticket.nome}
            <button
              className="button is-small is-info" onClick={() => {
                onHandleClick()
                setDisable(true)
              }}>{t('ticketDetail.showForm')}</button>
          </HeaderText>
          <ContainerBase>
            {openRedirect &&
              <RedirectModal
                openRedirect={openRedirect}
                setOpenRedirect={closeRedirectModal}
                chamadoId={String(params.id) as string}
              />}
            {openModalPrint &&
              <ModalDetailPrint
                openModal={openModalPrint}
                setOpenModal={setOpenModalPrint}
                selected={selected as ChecklistReportType} />}
            {openImgPreview &&
              <ModalImageOnTicket
                image={imgPreview}
                open={openImgPreview}
                setOpen={closeImgPreview}
              />}
            {open &&
              <ModalImagePreview
                image={image}
                open={open}
                setOpen={closeAndAtt}
              />}
            <ModalDeny
              openRefuse={openRefuse}
              setOpenRefuse={setOpenRefuse}
              status={objectStatus.status}
              mutate={remove}
            />
            <div className="columns">
              <Column style={{ padding: '25px' }} className="column">
                <Paragraph>
                  {t('ticketDetail.dateIn')}
                  &nbsp;&nbsp;
                  <span>
                    {ticket && new Date(ticket.inicio).toLocaleString()}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('ticketDetail.dateLimit')}
                  &nbsp;&nbsp;
                  <span
                    style={{ color: (ticket) && ticket.dataPrevisao > Number(objectStatus.dataAcompanhamento) ? '' : 'red' }}
                  >
                    {ticket && new Date(ticket.dataPrevisao).toLocaleString()}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('ticketDetail.fromName')}
                  &nbsp;&nbsp;
                  <span>
                    {selected ? selected.nome : 'Formulário não econtrado, pode não existir ou ainda estar em upload.'}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('ticketDetail.initial')}
                  &nbsp;&nbsp;
                  <span>
                    {selected && new Date(selected.inicio).toLocaleString()}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('ticketDetail.final')}&nbsp;&nbsp;
                  <span>
                    {!!selected && new Date(selected.fim).toLocaleString()}
                  </span>
                </Paragraph>
                <Paragraph>
                  Responsável:&nbsp;&nbsp;
                  <span>
                    {ticket && (ticket.responsavelNome || '-')}
                  </span>
                </Paragraph>
              </Column>
              <Column className="column">
                <Paragraph>
                  {t('ticketDetail.user')}&nbsp;&nbsp;<span>{ticket && ticket.usuarioNome?.startsWith('anonimo') ? 'Anonimo' : ticket?.usuarioNome}</span>
                </Paragraph>
                <Paragraph>
                  {t('ticketDetail.manage')}&nbsp;&nbsp;<span>{ticket && ticket?.nomeGerencia}</span>
                </Paragraph>
                <Paragraph>
                  {hierarchyLabel.cliente}:&nbsp;&nbsp;<span>{ticket && ticket?.nomeCliente}</span>
                </Paragraph>
                <Paragraph>
                  {hierarchyLabel.local}:&nbsp;&nbsp;<span>{ticket && ticket?.nomeLocal}</span>
                </Paragraph>
                <Paragraph>
                  {hierarchyLabel.conjunto}:&nbsp;&nbsp;<span>{ticket && ticket?.nomeConjunto}</span>
                </Paragraph>
                <Paragraph>
                  {hierarchyLabel.unidade}:&nbsp;&nbsp;<span>{ticket && ticket?.nomeUnidade}</span>
                </Paragraph>
              </Column>
            </div>
            {ticket && ticket.itemId && <ContainerBase className="columns" style={{ border: '1px solid lightgray', margin: '5px' }}>
              <div className="column" style={{ display: 'flex', flexDirection: 'column' }}>
                <Paragraph>
                  Item:&nbsp;
                  <span>
                    {item?.nome}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('notifications.ticket.Subitem_chamado')}:&nbsp;
                  <span>
                    {subitem?.nome}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('notifications.ticket.Resposta_subtitem')}:&nbsp;
                  <span>
                    {ticket.execucaoChecklist?.itens?.find((item: ItemChecklist) => item.id === ticket.itemId)?.subitens.filter((ele) => ele.id === ticket.subitemId).map((subItem) => (
                      <div>
                        <ul>
                          {renderSwitch(subItem as unknown as ChecklistSubitemReport)}
                        </ul>
                      </div>
                    ))}
                  </span>
                </Paragraph>
              </div>
              <div className="column">
                <Paragraph>
                  {t('notifications.ticket.Tipo_subitem')}:&nbsp;
                  <span>
                    {subitem?.tipo ? tipoExibido(subitem?.tipo) : ''}
                  </span>
                </Paragraph>
                <Paragraph>
                  {t('notifications.ticket.Fotos_subitem')}:&nbsp;
                </Paragraph>
                <span>
                  <ImagesCmp images={subitem?.img as string[]} selected={selected as ChecklistReportType} />
                </span>
              </div>
            </ContainerBase>}
            <ParagraphMsg>
              {t('ticketDetail.message')}&nbsp;&nbsp;<span>{!!ticket && ticket.instrucao}</span>
            </ParagraphMsg>
          </ContainerBase>
          {!!ticket && ticket.status === 'pendente' && <ContainerBase style={{ display: 'flex', justifyContent: 'space-around', padding: '10px 0px 10px 0px' }}>
            <button
              disabled={ticket?.block}
              onClick={() => {
                accept('emandamento')
              }}
              className="button is-success"
            >
              {t('notifications.ticket.Aceitar')}
            </button>
            <button
              disabled={ticket?.block}
              onClick={() => {
                remove('recusado')
              }}
              className="button is-danger"
            >
              {t('notifications.ticket.Recusar')}
            </button>
            {ticket.encaminha && <button
              disabled={ticket?.block}
              onClick={() => setOpenRedirect(true)}
              className="button is-info">
              {t('notifications.ticket.Redirecionar')}
            </button>}
          </ContainerBase>}
          {!!ticket && ticket.historicoChamado && <ContainerBase>
            <ParagraphMsg>
              {t('notifications.ticket.Lista_acoes')}
            </ParagraphMsg>
          </ContainerBase>}
          <ContainerBase style={{ padding: '25px' }}>
            <div style={{ border: '1px solid lightgray', padding: '30px', margin: '5px' }}>
              <p style={{ color: 'gray', fontSize: '1em', fontWeight: 500, textAlign: 'center' }}>
                {!!ticket && getStatus(ticket.status)}
              </p>
            </div>
            {ticket?.status === 'emandamento' &&
              <>
                <Column>
                  <HeaderText style={{ textAlign: 'center' }}>
                    {t('ticketDetail.doAction')}
                  </HeaderText>
                  <div className="columns">
                    <div className="column">
                      <Paragraph>
                        {t('ticketDetail.dateConclusion')}
                      </Paragraph>
                      <ReactDatePicker
                        showMonthDropdown
                        showYearDropdown
                        selected={objectStatus.dataAcompanhamento as Date}
                        onChange={(date: Date) => setObjectStatus({ ...objectStatus, dataAcompanhamento: (date.setHours(23, 59, 59)) })}
                        locale="pt"
                        dateFormat='dd/MM/yyyy'
                        className='input is-small'
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className="column">
                      <Paragraph>
                        {t('ticketDetail.action')}
                      </Paragraph>
                      {!verificaResponsavel() && (
                        <span className="tag is-warning">Apenas o Responsavel "{ticket.responsavelNome || 'Nome não encontrado'}" pode Concluir esse chamado</span>
                      )}
                      <select onChange={(e) => setObjectStatus({ ...objectStatus, status: e.target.value })} className="select is-small is-fullwidth">
                        <option value={'emandamento'}>
                          {t('ticketDetail.proceed')}
                        </option>
                        <option value={'concluido'} disabled={!verificaResponsavel()}>
                          {t('ticketDetail.conclude')}
                        </option>
                        <option value={'cancelado'}>
                          {t('ticketDetail.cancel')}
                        </option>
                      </select>
                    </div>
                  </div>
                </Column>
                <div className="columns">
                  <div className="column">
                    <Paragraph>
                      {t('ticketDetail.comment')}
                    </Paragraph>
                    <textarea
                      onChange={(e) => setObjectStatus({ ...objectStatus, comentario: e.target.value })}
                      value={objectStatus.comentario}
                      className="textarea"
                      placeholder={t("ticketDetail.enterComment")}
                    />

                  </div>
                  <ButtonsContainer className="column">
                    <button
                      disabled={disable || ticket?.block}
                      onClick={() => { objectStatus.status === 'recusado' || objectStatus.status === 'cancelado' ? remove(objectStatus.status) : accept(objectStatus.status) }}
                      className="button is-small is-success"
                    >
                      {t('ticketDetail.confirm')}
                    </button>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FileInput2
                        disabled={ticket?.block}
                        value={undefined}
                        type="file"
                        id="img"
                        name="img"
                        accept="image/*"
                        multiple={true}
                        onChange={(e) => renderImages(e.target.files)}
                        theme={t('users.userRegister.selectMessage')}
                      />
                      {image && <div className="columns" style={{ border: '1px solid lightgray', margin: '5px', height: '10em', padding: '5px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '5px' }}>
                          <button
                            disabled={ticket?.block}
                            value={image}
                            onClick={() => setImage('')}
                            className="button is-small is-danger"
                          >
                            {t('notifications.ticket.Remover')} <DeleteIcon />
                          </button>
                          <img
                            onClick={() => setOpen(true)}
                            style={{ height: '75%', width: '150px', padding: '20px', backgroundColor: 'lightgray' }}
                            alt='foto'
                            src={image}
                            width={100}
                          />
                        </div>
                      </div>}
                    </div>
                  </ButtonsContainer>
                </div>
              </>
            }
          </ContainerBase>
          <ContainerBase>
          </ContainerBase>
        </Container>}
      </PageContentContainer>
    </MainPageContainer>
  )
}
