import { ChecklistReportType, ChecklistSubitemReport } from "../../../../service/Reports"
import ImagesCmp from "../../../ImagesCmp"
import ImagesCmpAssinatura from "../../../ImagesCmpAssinatura"

const RenderSwitch = (param: ChecklistSubitemReport, showNotSelected: boolean, selected: ChecklistReportType) => {
  switch (param.tipo) {
    case 'multiple':
      return (
        <>
          <th style={{ borderLeft: '1px solid black', marginLeft: '10px', paddingLeft: '10px' }}>
            {param.selecao ? param.selecao.map((e, i) => {
              if (showNotSelected || (param.selecionados && param.selecionados[i])) {
                return (
                  <li key={i} style={{
                    wordBreak: 'break-word',
                    color: 'gray',
                    listStyleType: `${(param.selecionados && param.selecionados[i]) ? 'circle' : 'square'}`,
                    fontWeight: `${(param.selecionados && param.selecionados[i]) ? '900' : '250'}`,
                    marginLeft: `${(param.selecionados && param.selecionados[i]) ? '20px' : '10px'}`,

                  }}>
                    {e}
                  </li>
                )
              } else if ((param.selecionados && param.selecionados[i])) return (
                <li key={i} style={{
                  wordBreak: 'break-word',
                  color: 'gray',
                  fontWeight: `${(param.selecionados && param.selecionados[i]) ? '900' : '250'}`,
                  marginLeft: `${(param.selecionados && param.selecionados[i]) ? '20px' : '10px'}`
                }}>
                  {e}
                </li>)
            }) : <li></li>}
          </th>
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
          {param.ocorrencia && <p>{`Ocorrencia: ${param.ocorrencia}`}</p>}
        </>
      )
    case 'unique':
      return (
        <>
          <th style={{ borderLeft: '1px solid black', marginLeft: '10px', paddingLeft: '10px' }}>
            {param.selecao ? param.selecao.map((e, i) => {
              if (showNotSelected || (param.selecionados && param.selecionados[i])) {
                return (
                  <li key={i} style={{
                    color: 'gray',
                    listStyleType: `${(param.selecionados && param.selecionados[i]) ? 'circle' : 'square'}`,
                    fontWeight: `${(param.selecionados && param.selecionados[i]) ? '900' : '250'}`,
                    marginLeft: `${(param.selecionados && param.selecionados[i]) ? '20px' : '10px'}`,
                  }}>
                    {e}
                  </li>
                )
              } else if ((param.selecionados && param.selecionados[i])) return <>{e}</>
            }) : <li>a</li>}
          </th>
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
          {param.obs && <p>{`Ocorrencia: ${param?.obs}`}</p>}
        </>
      )
    case 'avaliacao':
      return (
        <>
          {/* {window.location.pathname === '/aprovacao' && <>
            {param.chamados && param.chamados && <span style={{ display: 'flex', textAlign: 'initial', fontWeight: 700, fontSize: '.6em' }}>Chamados: {param.chamados?.length}</span>}
            {param.chamados && param.chamados.map((e) => (
              <TicketEdit value={e} editing={editing} t={t} />
            ))}
          </>} */}
          <th style={{ borderLeft: '1px solid black', marginLeft: '10px', paddingLeft: '10px' }}>
            {param.avaliacao ? param.avaliacao.map((e, i, arr) => {
              if (showNotSelected || ((param.nota || param.nota === 0) && param.nota === i)) {
                return (
                  <div key={i} style={{ display: 'flex' }}>
                    <li style={{
                      color: 'gray',
                      listStyleType: `${((param.nota || param.nota === 0) && param.nota === i) || (param.nota === -1 && e === arr[arr.length - 1]) ? 'circle' : 'square'}`,
                      marginRight: '5px', fontWeight: `${((param.nota || param.nota === 0) && param.nota === i) || (param.nota === -1 && e === arr[arr.length - 1]) ? '900' : '250'}`,
                      marginLeft: `${((param.nota || param.nota === 0) && param.nota === i) || (param.nota === -1 && e === arr[arr.length - 1]) ? '20px' : '10px'}`,
                      wordBreak: 'break-word'
                    }}>
                      {`${e.valor}`}
                    </li>
                    <li style={{ color: 'gray', wordBreak: 'break-word', fontWeight: `${(param.nota === i) ? '900' : '250'}` }}>
                      {e.nome}
                    </li> 
                  </div>)
              } else return <></>
            }) : <li>a</li>}
          </th>
          {param.ocorrencia && <p>{`Resposta: ${param.ocorrencia}`}</p>}
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
        </>
      )
    case 'list':
      return (
        <>
          {/* {window.location.pathname === '/aprovacao' && <>
            {param.chamados && <span style={{ display: 'flex', textAlign: 'initial', fontWeight: 700, fontSize: '.6em' }}>Chamados: {param.chamados?.length}</span>}
            {param.chamados && param.chamados.map((e) => (
              <TicketEdit value={e} editing={editing} t={t} />
            ))}
          </>} */}
          <div style={{ borderLeft: '1px solid black', marginLeft: '10px', paddingLeft: '10px' }}>
            {param.selecionados ? param.selecionados.map((e, i) => {
              if (showNotSelected || (param.selecionados && param.selecionados[i].texto === e.texto)) {
                return (
                  <li key={i} style={{
                    color: 'gray',
                    fontWeight: `${(param.selecionados && param.selecionados[i].texto === e.texto) ? '700' : '250'}`,
                    marginLeft: `${(param.selecionados && param.selecionados[i].texto === e.texto) ? '30px' : '2px'}`,
                    wordBreak: 'break-word'
                  }}>
                    {e.texto}
                  </li>)
              } else return <></>
            }) : <li>a</li>}
          </div>
          {param.obs && <p>{`Resposta: ${param.obs}`}</p>}
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
        </>
      )
    case 'textoLongo': case 'hora': case 'email': case 'telefone': case 'rg': case 'cpfcnpj': case 'cep': case 'placa':
      return (
        <>
          {/* {window.location.pathname === '/aprovacao' && <>
            {param.chamados && <span style={{ display: 'flex', textAlign: 'initial', fontWeight: 700, fontSize: '.6em' }}>Chamados: {param.chamados?.length}</span>}
            {param.chamados && param.chamados.map((e) => (
              <TicketEdit value={e} editing={editing} t={t} />
            ))}
          </>} */}
          {param.ocorrencia && <p style={{ color: 'gray', }}>{`Resposta: ${param.ocorrencia}`}</p>}
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
          {param.obs && <p>{`Ocorrencia: ${param?.obs}`}</p>}
        </>
      )
    case 'data':
      return (
        <>
          {/* {window.location.pathname === '/aprovacao' && <>
            {param.chamados && <span style={{ display: 'flex', textAlign: 'initial', fontWeight: 700, fontSize: '.6em' }}>Chamados: {param.chamados?.length}</span>}
            {param.chamados && param.chamados.map((e) => (
              <TicketEdit value={e} editing={editing} t={t} />
            ))}
          </>} */}
          {param.ocorrencia && <p style={{ color: 'gray', }}>{`Resposta: 
          ${typeof param.ocorrencia === 'number' ? new Date(param.ocorrencia).toLocaleDateString() : param.ocorrencia}`
          }</p>}
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
          {param.obs && <p>{`Ocorrencia: ${param?.obs}`}</p>}
        </>
      )
    case 'assinatura':
      return (
        <>
          {/* {window.location.pathname === '/aprovacao' && <>
            {param.chamados && <span style={{ display: 'flex', textAlign: 'initial', fontWeight: 700, fontSize: '.6em' }}>Chamados: {param.chamados?.length}</span>}
            {param.chamados && param.chamados.map((e) => (
              <TicketEdit value={e} editing={editing} t={t} />
            ))}
          </>} */}
          {param.assinatura &&
            <ImagesCmpAssinatura selected={selected} images={[param.assinatura]} />}
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
          {param.obs && <p style={{ color: 'gray', }}>{`Ocorrencia: ${param?.obs}`}</p>}
        </>
      )
    default:
      return (
        <div>
          {/* {window.location.pathname === '/aprovacao' && <>
            {param.chamados && <span style={{ display: 'flex', textAlign: 'initial', fontWeight: 700, fontSize: '.6em' }}>Chamados: {param.chamados?.length}</span>}
            {param.chamados && param.chamados.map((e) => (
              <TicketEdit value={e} editing={editing} t={t} />
            ))}
          </>} */}
          {
            param.ocorrencia &&
            <li
              className='column'
              style={{ display: 'flex', color: 'gray', }}
            >
              {`Resposta: ${param.ocorrencia}`}
            </li>
          }
          {param.img && (
            <ImagesCmp selected={selected} images={param.img} />
          )}
          {param.obs && <p style={{ color: 'gray', }}>{`Ocorrencia: ${param?.obs}`}</p>}
        </div>
      )
  }
}

export default RenderSwitch;