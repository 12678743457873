import React, { useState } from 'react'
import PlanoDeAcao, { planoDeAcaoType, taskType } from '../../../service/PlanoDeAcao';
import { PlanoDatailContainer, SpanBelongs, Title } from '../style/stylesPlano';
import { Label } from '../../Notifications/style/SingleNotificationStyle';
import AttToken from '../../../helpers/attToken';
import Loading from '../../Loading/Loading';
import ReactDatePicker from 'react-datepicker';
import queryClient from '../../../service/query';
import { toast } from 'react-toastify';
import getEndOfToday from '../../../helpers/getEndOfToday';
import UserSelector from '../../UserSelector';
import { Tooltip } from '@material-ui/core';
import { BiArrowFromBottom, BiArrowFromTop, BiPlus, BiTrash } from 'react-icons/bi';

type props = {
  openModal: boolean,
  setOpenModal: Function,
  planos: planoDeAcaoType[],
  setPlanos: Function,
  closeFatherModal: Function,
  analiseId: string
}

type FormPaProps = {
  plano: planoDeAcaoType,
  setPlanos: Function,
  planos: planoDeAcaoType[],
  i: number,
}

type TaskCompProps = {
  index: number,
  task: taskType
  setPlanos: Function,
  planos: planoDeAcaoType[],
  plano: planoDeAcaoType,
  setMinDateValue: Function
}

// const usersService = new Users();
const planoService = new PlanoDeAcao();

const TaskComp = ({ task, index, planos, setPlanos, plano, setMinDateValue }: TaskCompProps) => {
  const handleAddTaskResponsible = (uid: string) => {
    task.responsavel = uid;
    setPlanos([...planos]);
  }

  return (
    <div key={index} className='naoConfs'>
      <div className='tasks'>
        <Label style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ width: '25%' }}>Nome da tarefa:&nbsp;</span>
          <input
            className='input is-fullwidth'
            type='text'
            value={task.nome}
            onChange={(e) => {
              task.nome = e.target.value;
              setPlanos([...planos]);
            }}
            required
          />
        </Label>
        <div style={{ display: 'flex', alignItems: 'center', color: 'gray', fontSize: '12px', fontWeight: '600', textAlign: 'left', margin: '5px 0', }}>
          <span style={{ width: '25%' }}>Responsavel:&nbsp;</span>
          <UserSelector
            callback={handleAddTaskResponsible}
            userLabel={'Selecione o usuário responsável pela Tarefa'}
            defaultSelected={task.responsavel || null}
          />
        </div>
        <Label style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ width: '25%' }}>Realizar tarefa até:&nbsp;</span>
          <ReactDatePicker
            showMonthDropdown
            showYearDropdown
            portalId="root-portal"
            locale="pt"
            dateFormat='dd/MM/yyyy'
            wrapperClassName="inputDateStyle"
            className='input'
            minDate={getEndOfToday().endDate}
            todayButton="Hoje"
            maxDate={plano.dataLimite ? new Date(plano.dataLimite) : null}
            onKeyDown={(e) => e.preventDefault()}
            selected={task.dataLimite ? new Date(task.dataLimite) : new Date()}
            timeIntervals={1}
            onChange={(date: Date) => {
              const thisMilisseconds = new Date(date).getTime();
              let minDate = thisMilisseconds;
              plano.tasks.forEach((task, i) => {
                if (i !== index && task.dataLimite > minDate) {
                  minDate = task.dataLimite;
                }
              });
              setMinDateValue(new Date(minDate));
              task.dataLimite = new Date(date).getTime()
              setPlanos([...planos]);
            }}
            required
          />
        </Label>
        <Label style={{ display: 'flex', alignItems: 'center' }} className='checkbox'>
          <span style={{ width: '27%' }}>Obrigatório evidencia:&nbsp;</span>
          <input
            className='checkbox'
            type='checkbox'
            checked={task.obrigatorioEvidencia}
            onChange={(e) => {
              task.obrigatorioEvidencia = e.target.checked;
              setPlanos([...planos]);
            }}
          />
        </Label>
        <Label style={{ display: 'flex', alignItems: 'center' }} className='checkbox'>
          <textarea
            className='textarea has-fixed-size'
            onChange={(e) => {
              task.detalhes = e.target.value;
              setPlanos([...planos]);
            }}
            placeholder='Detalhar tarefa (Opcional)'
            maxLength={1000}
          />
        </Label>
        <button
          onClick={() => {
            const newTasks = plano.tasks.filter((el, ind) => ind !== index);
            plano.tasks = newTasks;
            setPlanos([...planos]);
          }}
          className='button is-danger'
        >
          <BiTrash />
        </button>
      </div>
    </div>
  )
}

const FormPA = ({ plano, setPlanos, planos, i }: FormPaProps,) => {
  const [minDateValue, setMinDateValue] = useState<Date>(getEndOfToday().endDate);
  const [inverteOrdem, setInverteOrdem] = useState(true);

  const addTask = () => {
    const defaultTask: taskType = {
      nome: 'Nova Tarefa',
      status: 'pendente',
      responsavel: '',
      obrigatorioEvidencia: false,
      detalhes: '',
      dataLimite: getEndOfToday().endDate.getTime(),
    }
    if (!plano.tasks || !plano.tasks.length) {
      plano.tasks = [defaultTask]
    } else { plano.tasks.push(defaultTask) }
    setPlanos([...planos])
  }

  const handleAddPlanResponsible = (uid: string) => {
    plano.responsavel = uid;
    setPlanos([...planos]);
  }

  return (
    <PlanoDatailContainer key={i}>
      <Title>Opções do Plano:</Title>
      <Label style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ width: '25%' }}>Nome do Plano de Ação:&nbsp;</span>
        <input
          className='input is-fullwidth'
          type="text"
          value={plano.nome}
          onChange={(e) => {
            plano.nome = e.target.value;
            setPlanos([...planos])
          }}
          required
        />
      </Label>
      <div style={{ display: 'flex', alignItems: 'center', color: 'gray', fontSize: '12px', fontWeight: '600', textAlign: 'left', margin: '5px 0', }}>
        <span style={{ width: '25%' }}>Responsavel pelo Plano de Ação:&nbsp;</span>
        <UserSelector
          callback={handleAddPlanResponsible}
          userLabel={'Selecione o usuário responsável pelo Plano de Ação'}
          defaultSelected={plano.responsavel || null}
        />
      </div>
      <Label style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ width: '25%' }}>Finalizar Plano até:&nbsp;</span>
        <ReactDatePicker
          showMonthDropdown
          showYearDropdown
          portalId="root-portal"
          locale="pt"
          dateFormat='dd/MM/yyyy'
          wrapperClassName="inputDateStyle"
          className='input'
          minDate={minDateValue}
          onKeyDown={(e) => e.preventDefault()}
          todayButton="Hoje"
          selected={plano.dataLimite ? new Date(plano.dataLimite) : new Date()}
          onChange={(date: Date) => {
            plano.dataLimite = new Date(date).getTime()
            setPlanos([...planos]);
          }}
          required
        />
      </Label>
      <Title>Não Conformidades:</Title>
      <div className='naoConfs'>
        {plano.naoConformidades.map((sub, index) => (
          <span key={index} style={{ wordBreak: 'break-all', maxWidth: '95%' }}>{index + 1} - {sub.nome}</span>
        ))}
      </div>
      <Title>Definir Tarefas Para Este Plano:</Title>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-around', marginTop: '15px' }}>
        <Tooltip title="Adicionar Tarefa" style={{ width: '25%' }}>
          <button
            type='button'
            className='button is-success is-small'
            onClick={() => addTask()}
          >
            <BiPlus />
          </button>
        </Tooltip>
        <Tooltip title={`Ver tarefas ${inverteOrdem ? 'antigas primeiro' : 'recentes primeiro'} (ordem de criação)`} style={{ width: '25%' }}>
          <button
            onClick={() => setInverteOrdem(!inverteOrdem)}
            className='button is-small is-info'
            type='button'
          >
            {inverteOrdem ? <BiArrowFromBottom /> : <BiArrowFromTop />}
          </button>
        </Tooltip>
      </div>
      {(!plano.tasks || !plano.tasks.length) && <SpanBelongs>Nenhuma tarefa criada ainda</SpanBelongs>}
      <div style={{ display: 'flex', flexDirection: inverteOrdem ? 'column-reverse' : 'column' }}>
        {plano.tasks && plano.tasks.map((task, index) => (
          <TaskComp
            task={task}
            index={index}
            planos={planos}
            setPlanos={setPlanos}
            plano={plano}
            setMinDateValue={setMinDateValue}
          />
        ))}
      </div>
    </PlanoDatailContainer>
  )
}

export default function OpenPlanoModal({
  openModal,
  setOpenModal,
  planos,
  setPlanos,
  closeFatherModal,
  analiseId
}: props) {
  const [sending, setSending] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSending(true);
    const token = await AttToken();
    if (token) {
      await planoService.startPlano(token, analiseId, planos);
      queryClient.resetQueries(['GET_ANALISES_PENDING']);
      queryClient.resetQueries(['GET_ANALISES_FINALIZADAS']);
      setSending(false);
      setOpenModal();
      closeFatherModal();
      toast.success(`${planos.length} ${planos.length > 1 ? 'Planos Abertos!' : 'Plano Aberto!'}`);
    } else {
      setSending(false);
      toast.error(`Falha ao abrir planos`);
    }
  }

  return (
    <div className={`modal ${openModal ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{`Abrindo ${planos.length} ${planos.length > 1 ? 'Planos' : 'Plano'}`}</p>
          <button
            onClick={() => {
              setOpenModal()
            }}
            className="delete"
            aria-label="fechar"
            type='button'
            disabled={sending}
          />
        </header>
        <section className="modal-card-body">
          {!sending ? (
            <form onSubmit={(e) => handleSubmit(e)}>
              {planos.length && planos.map((plan, index) => (
                <FormPA
                  plano={plan}
                  planos={planos}
                  setPlanos={setPlanos}
                  i={index}
                />
              ))}
              <button
                className='button is-success is-fullwidth'
                disabled={planos.some(plano => !plano.tasks || !plano.tasks.length)}
                type='submit'
              >
                {`Finalizar e Abrir ${planos.length > 1 ? 'Planos de Ação' : 'Plano de Ação'}`}
              </button>
            </form>
          ) : (
            <div>
              <h1>Abrindo Planos</h1>
              <Loading />
            </div>
          )}
        </section>
      </div>
    </div>
  )
}
